import './sass/app.scss'
import { Routes, Route, Link, useParams, useNavigate } from 'react-router-dom'
import { HeaderBar } from './common/template/header_bar'
import TourManagmentMainClass from './app/tour_management/tour_management_main'
import Dashboard from './app/dashboard/dashboard'
import { Footer } from './common/template/footer'
import GuideProfile from './app/guide/profile/guide_profile'
import TourPackage from './app/tour_package/tour_package'
import GuideApplication from './app/guide_application/guide_application'
import { useEffect, useState } from 'react'
import SignIn from './app/auth/sign_in'
import { SignUp } from './app/auth/sign_up'
import UserTravel from './app/user/order/my_tours'
import Payment from './app/user/payment/payment'
import PageNotFound from './common/template/errors/404'
import UserProfile from './app/user/profile/user_profile'
import { ForgotPassword, ResetPassword } from './app/auth/password'
import PaymentCompletion from './app/user/payment/confirmation'
import MyTours from './app/user/order/my_tours'
import MyTourProfile from './app/user/order/tour_detail'
import About from './app/admin/about'
import GuideTermsOfService from './app/guide_application/guide_terms_of_service'
import UserTermsOfService from './app/user/terms_of_service/terms_of_service'

export const App = () => {
  const [currentPath, setCurrentPath] = useState<string>('')
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? null
    const userInfo = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )
    if (token && userInfo) {
      setIsAuthenticated(true)
    }
  }, [])

  return (
    <>
      <div className="uri-travel-wrapper">
        <HeaderBar
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setCurrentPath={setCurrentPath}
              />
            }
          />
          ;
          <Route path="/about_us" element={<About />} />;
          <Route
            path="/tour_management"
            element={
              <TourManagmentMainClass
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setCurrentPath={setCurrentPath}
              />
            }
          />
          <Route
            path="/guide_profile/:id"
            element={
              <GuideProfile
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setCurrentPath={setCurrentPath}
              />
            }
          />
          <Route
            path="/tour_package/:id"
            element={
              <TourPackage
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setCurrentPath={setCurrentPath}
                type="offer"
              />
            }
          />
          <Route
            path="/become_guide"
            element={
              <GuideApplication
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setCurrentPath={setCurrentPath}
              />
            }
          />
          <Route
            path="/guide_terms_of_service"
            element={<GuideTermsOfService />}
          />
          ;
          <Route
            path="/my_tour/"
            element={
              <MyTours
                isAuthenticated={isAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/my_tour/:id"
            element={
              isAuthenticated ? (
                <MyTourProfile
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                  setCurrentPath={setCurrentPath}
                  type="reservation"
                />
              ) : (
                <SignIn
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                  currentPath={currentPath}
                />
              )
            }
          />
          <Route
            path="/sign_in"
            element={
              <SignIn
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/sign_up"
            element={
              <SignUp
                isAuthenticated={isAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/forgot_password"
            element={
              <ForgotPassword
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/reset_password"
            element={
              <ResetPassword
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/checkout/:order_id"
            element={
              <Payment
                isAuthenticated={isAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="payment_completion/:order_id"
            element={
              <PaymentCompletion
                isAuthenticated={isAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route
            path="/user_terms_of_service"
            element={<UserTermsOfService />}
          />
          <Route
            path="/my_profile"
            element={
              <UserProfile
                isAuthenticated={isAuthenticated}
                currentPath={currentPath}
              />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
}
