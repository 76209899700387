import React from 'react'
import './../../../sass/errors/errors.scss'

export default function PageNotFound() {
  return (
    <React.Fragment>
      <div className="container error app-container mt-3">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <img
              className="error-404"
              src={require('./../../../assets/images/errors/404.png')}
              alt="page not found"
            />
          </div>
          <div className="col-md-6 align-self-center">
            <a href="https://storyset.com/web">Web illustrations by Storyset</a>
            <h1>404</h1>
            <h2>{"UH OH! You're lost."}</h2>
            <p>해당 요청이나 데이터를 찾을수 없습니다.</p>
            <a type="button" href="/" className="btn green">
              메인 홈
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
