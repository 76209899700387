// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../assets/images/aboutus.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-check-inline{margin-right:1px !important}*{box-sizing:border-box}.center{margin:auto}.main{padding:0 20px;overflow:hidden}.right{padding:10px 15px;margin-top:7px;overflow:auto}.banner{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:center;width:100%;height:200px}@media only screen and (max-width: 800px){.main{width:80%;padding:0}.right{width:100%}}@media only screen and (max-width: 500px){.menu,.main{width:100%}.right{width:100%}}", "",{"version":3,"sources":["webpack://./src/sass/guide_application/guide_application.scss"],"names":[],"mappings":"AAAA,mBACE,2BAAA,CAGF,EACE,qBAAA,CAEF,QACE,WAAA,CAEF,MACE,cAAA,CACA,eAAA,CAEF,OACE,iBAAA,CACA,cAAA,CACA,aAAA,CAEF,QACE,wDAAA,CACA,qBAAA,CACA,0BAAA,CACA,UAAA,CACA,YAAA,CAGF,0CAEE,MACE,SAAA,CACA,SAAA,CAEF,OACE,UAAA,CAAA,CAGJ,0CAEE,YAEE,UAAA,CAEF,OACE,UAAA,CAAA","sourcesContent":[".form-check-inline {\n  margin-right: 1px !important;\n}\n\n* {\n  box-sizing: border-box;\n}\n.center {\n  margin: auto;\n}\n.main {\n  padding: 0 20px;\n  overflow: hidden;\n}\n.right {\n  padding: 10px 15px;\n  margin-top: 7px;\n  overflow: auto;\n}\n.banner {\n  background-image: url('./../../assets/images/aboutus.jpg');\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 200px;\n}\n\n@media only screen and (max-width: 800px) {\n  /* For tablets: */\n  .main {\n    width: 80%;\n    padding: 0;\n  }\n  .right {\n    width: 100%;\n  }\n}\n@media only screen and (max-width: 500px) {\n  /* For mobile phones: */\n  .menu,\n  .main {\n    width: 100%;\n  }\n  .right {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
