import React, { Component } from 'react'
import './password_strength_checker.scss'
import zxcvbn from 'zxcvbn'
import { create_password_label } from './password_strength_checker_components'

class PasswordStrengthChecker extends Component<{ password: string }, any> {
  render() {
    const vm = this
    const { password } = vm.props

    const strength_check_res = zxcvbn(password)

    return (
      <div className="password-strength-checker">
        <progress
          className={`password-strength-checker-progress strength-${create_password_label(
            strength_check_res.score
          )}`}
          value={strength_check_res.score}
          max="4"
        />
        <br />
        <label className="password-strength-checker-label">
          {password && (
            <>
              <strong>Password strength:</strong>{' '}
              {create_password_label(strength_check_res.score)}
            </>
          )}
        </label>
      </div>
    )
  }
}

export default PasswordStrengthChecker
