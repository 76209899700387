import React from 'react'
import './../../sass/dashboard/dashboard.scss'
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledCarousel,
} from 'reactstrap'

export const processFormatHeaderImages = () => {
  return (
    <UncontrolledCarousel
      items={[
        {
          altText: 'slide 1',
          caption: '',
          key: 1,
          src: require('./../../assets/images/slide1.png'),
        },
        {
          altText: 'slide 2',
          caption: '',
          key: 2,
          src: require('./../../assets/images/slide2.png'),
        },
        {
          altText: 'slide 3',
          caption: '',
          key: 3,
          src: require('./../../assets/images/slide3.png'),
        },
      ]}
    />
  )
}

export const displayTourPackages = (
  vm: any,
  tours_data: any,
  guide_data: any
) => {
  const tour_list = []

  const guide_list = []

  for (let i = 0; i < tours_data.length; i++) {
    const tour = tours_data[i]

    const basic_info = tour.information

    let price = ''
    let max_capacity: string | number = ''
    if (Array.isArray(tour.price)) {
      const tour_price = []
      const capacity = []

      const price_info = tour.price

      for (const i in price_info) {
        tour_price.push(price_info[i].price)
        capacity.push(price_info[i].maxCapacity)
      }

      const high_price = Math.max(...tour_price)
      const row_price = Math.min(...tour_price)

      price =
        '$' +
        row_price.toLocaleString('en') +
        ' - ' +
        high_price.toLocaleString('en')

      max_capacity = Math.max(...capacity)
    } else {
      price = '$' + tour.price.toLocaleString('en')

      max_capacity = basic_info.maxCapacity
    }

    const rate_tmp = (tour.rating.stars / tour.rating.counts).toFixed(2)

    let rate: JSX.Element | string = ''
    if (!isNaN(parseInt(rate_tmp))) {
      rate = (
        <React.Fragment>
          <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
        </React.Fragment>
      )
    }

    tour_list.push(
      <Col className="mb-4" key={'tour_card_' + i}>
        <Card
          className="dashboard-tour-card"
          onClick={(ev) => vm.handleClickTourCard(ev, tour._id)}
          aria-hidden="true"
        >
          <CardImg
            className="dashboard-tour-image-card"
            alt="Card image cap"
            src={require('./../../assets/images/' + tour.productPhoto[0])}
            top
            width="100%"
            style={{
              objectFit: 'cover',
            }}
          />
          <CardBody>
            <CardTitle tag="h6" className="dashboard-tour-card-title">
              {basic_info.title}
            </CardTitle>
            <CardSubtitle tag="h6" className="dashboard-tour-card-info">
              {rate}
            </CardSubtitle>
            <p className="mb-0 dashboard-tour-card-info">
              {price} / 최대 {max_capacity} 인
            </p>
            <p className="mb-0 dashboard-tour-card-info">
              {basic_info.duration} 시간
            </p>
          </CardBody>
        </Card>
      </Col>
    )
  } // TODO:: replace by map func with tour packages

  for (let j = 0; j < guide_data.length; j++) {
    if (guide_data[j].profile.profilePicture.length === 0) {
      guide_data[j].profile.profilePicture = 'avatar.png'
    }
    guide_list.push(
      <Col className="mb-4" key={'guide_card_' + j}>
        <div
          className="dashboard-guide-card"
          key={'guide_list_' + j}
          onClick={(ev) => vm.handleClickGuideThumnail(ev, guide_data[j]._id)}
          aria-hidden="true"
        >
          <img
            className="dashboard-guide-photo"
            src={require('./../../assets/images/' +
              guide_data[j].profile.profilePicture)}
            alt="guide_pic"
          />
        </div>
        <div className="dashboard-guide-name text-center">
          {guide_data[j].profile.displayName}
        </div>
      </Col>
    )
  }

  if (tours_data.length === 0) {
    tour_list.push(<p>가능한 투어가 0개 입니다.</p>)
    guide_list.push(<p>가능한 가이드가 0명 입니다.</p>)
  }

  return (
    <Row className="row-center">
      {/* <Col xs="12">
        <img
          src={require('./../../assets/images/' +
            vm.state.active_location.toLowerCase() +
            '.png')}
          className="img-fluid city-banner"
          alt="banner"
        />
      </Col> */}
      <Col md="12" className="mt-3">
        <div className="dasboard-title">현지 가이드 정보</div>
      </Col>
      <Row md="6" sm="4" xs="3">
        {guide_list}
      </Row>
      <Col md="12" className="mt-3">
        <div className="dasboard-title">투어 상품</div>
      </Col>
      <Row lg="4" md="3" sm="2" xs="1">
        {tour_list}
      </Row>
    </Row>
  )
}

export const setCityListForCategorizedTourPackages = (
  vm: any,
  active_location: string,
  locations: Array<string>
) => {
  const location_items: Array<JSX.Element> = locations.map(
    (location: string, key: number) => {
      const location_name = location_map_kr[location]

      let nav_link = (
        <NavLink onClick={() => vm.handleChangeActiveLocation(location)}>
          {location_name}
        </NavLink>
      )

      if (location === active_location) {
        nav_link = (
          <NavLink
            active
            onClick={() => vm.handleChangeActiveLocation(location)}
          >
            {location_map_kr[location]}
          </NavLink>
        )
      }

      return (
        <NavItem className="mr-2" key={key}>
          {nav_link}
        </NavItem>
      )
    }
  )

  return (
    <div className="mt-4 tour-locations">
      <Nav pills>{location_items}</Nav>
    </div>
  )
}

const location_map_kr: {
  [key: string]: string
} = {
  Vancouver: '밴쿠버',
  Victoria: '빅토리아',
  Toronto: '토론토',
  Quebec: '퀘벡',
  Whistler: '휘슬러',
  Joffre: '조프리',
  Qeubec: '퀘백',
  Alberta: '알버타',
}
