import React from 'react'
import { Col } from 'reactstrap'
import { ExchangeRate, PaymentInformation } from './types'

export const OrderDetail = (props: {
  price: number
  currency: string
  exchangeRate: ExchangeRate | null
}): JSX.Element => {
  return (
    <React.Fragment>
      <h5 className="fw-bold mb-2 text-center">요금 내역</h5>
      <div className="border-top pt-3 mb-2">
        <div className="row">
          <Col md={5}>
            <p className="pb-0 mb-0">투어 금액</p>
          </Col>
          <Col md={7} className="text-right">
            <p className="pb-0 mb-0">
              $
              {props.price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </Col>
          <Col md={5}>
            <p className="pb-0 mb-0">부과세 (12%)</p>
          </Col>
          <Col md={7} className="text-right">
            <p className="pb-0 mb-0">
              $
              {(props.price * 0.12).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </p>
          </Col>
        </div>
      </div>
      <div className="border-top pt-3 mb-2">
        <div className="row">
          <Col md={5}>
            <p className="pb-0 mb-0">
              <strong>총 금액({props.currency})</strong>
            </p>
          </Col>
          <Col md={7} className="text-right">
            <p className="pb-0 mb-0">
              <strong>
                $
                {(props.price * 1.12).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </strong>
            </p>
          </Col>
        </div>
        <div className="text-right">
          {props.exchangeRate ? (
            <p>
              현재 환율: {props.exchangeRate.CAD} {props.currency} {'<->'}{' '}
              {props.exchangeRate.WON}원
              <br />
              예상 원화 금액:{' '}
              {Math.round(
                props.price * 1.12 * props.exchangeRate.WON
              ).toLocaleString()}
              원
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export function ProductDetail(props: { information: any; guide: string }): any {
  if (props.information === null) {
    return ''
  }

  return (
    <React.Fragment>
      <h6 className="fw-bold mb-3">{props.information.title}</h6>
      <p className="tour-date mb-0 pb-0">
        <span className="fw-bold">날짜:</span> {props.information.tourDate}
      </p>
      <p className="tour-time mb-0 pb-0">
        <span className="fw-bold">시간:</span> {props.information.tourTime}
      </p>
      <p className="guide-name mb-0 pb-0">
        <span className="fw-bold">가이드</span> {props.guide}
      </p>
      <p className="meeting-location mb-0 pb-0">
        <span className="fw-bold">미팅장소</span>{' '}
        {props.information.meetingLocation}
      </p>
    </React.Fragment>
  )
}

export function PaymentDetail(props: {
  paymentInformation: PaymentInformation
}) {
  const { paymentInformation } = props

  return (
    <React.Fragment>
      <h6 className="fw-bold mb-3">{paymentInformation.paymentMethod}</h6>
      <p className="mb-0 pb-0">
        <span>카드 번호:</span>{' '}
        {paymentInformation.transcationId
          ? '****' + paymentInformation.transcationId.substring(-1, 4)
          : '****'}
      </p>
    </React.Fragment>
  )
}
