import { Field } from '@availity/form'
import React from 'react'
import { Row } from 'reactstrap'
import { DragAndDrop } from '../../common/system/drag_and_drop/drag_and_drop'
import { FileUploadZone } from '../../common/system/file_upload/file_upload'
import { SearchFilterComponentProps, InputFieldProp } from './types'

import { SelectField } from '@availity/select'
import { DropdownOptionsType } from '../../common/type/dropdown'

export const SearchFilter = (props: SearchFilterComponentProps) => {
  return <Row></Row>
}

type ImageUploadProps = {
  handleImageUpload: (
    e: DragEvent | React.ChangeEvent<HTMLInputElement>,
    type: string,
    id: string
  ) => void
  multipleUpload: boolean
  style?: object
  name: string
  id: string
}

export const ImageUpload = (props: ImageUploadProps) => {
  const accepted_file_type = '.gif,.giff,.jpeg,.jpg,.bmp,.png,.tiff,.tif'

  return (
    <div style={props.style ?? {}}>
      <DragAndDrop id={props.id} handleDrop={props.handleImageUpload}>
        <FileUploadZone
          handleChange={props.handleImageUpload}
          accept={accepted_file_type}
          multiple={props.multipleUpload}
          visible={true}
          name={props.name}
          id={props.id}
        />
      </DragAndDrop>
    </div>
  )
}

export const InputField = (props: {
  config: InputFieldProp
  value: number | string
  form_id: string
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    form_id: string,
    field_id: string,
    row_id: number
  ) => void
  handleSelectChange: (new_value: DropdownOptionsType, field_id: string) => void
}) => {
  const config = props.config

  const input_config = config.input_config

  let input: JSX.Element = <></>

  switch (props.config.input_type) {
    case 'text':
      input = (
        <Field
          name={config.id}
          id={config.id}
          label={config.label ?? ''}
          placeholder={input_config?.placeholder ?? ''}
          onChange={(e) => props.handleChange(e, props.form_id, config.id, 0)}
          required
        />
      )
      break
    case 'textarea':
      input = (
        <Field
          name={config.id}
          id={config.id}
          label={config.label ?? ''}
          type="textarea"
          placeholder={input_config?.placeholder ?? ''}
          onChange={(e) => props.handleChange(e, props.form_id, config.id, 0)}
          required
        />
      )
      break

    case 'select':
      input = (
        <SelectField
          label={config.label}
          name={config.id}
          isMulti={config.input_config?.is_multiple ?? false}
          options={config.options}
          onChange={(newValue) => props.handleSelectChange(newValue, config.id)}
        />
      )
      break
    default:
      break
  }

  return input
}

export const sanitizeRowIndexFromKey = (
  key: string,
  prefix: string
): number => {
  return parseInt(key.replace(prefix + '_', ''))
}
