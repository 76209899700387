import { Input, Form, Field, CheckboxGroup, Checkbox } from '@availity/form'
import axios from 'axios'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col } from 'reactstrap'
import PasswordStrengthChecker from '../../common/system/password_strength_checker/password_strength_checker'
import * as yup from 'yup'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './../../sass/auth/sign_up.scss'

export const SignUp = (props: any) => {
  const { currentPath, isAuthenticated, setIsAuthenticated } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const formRef = useRef<any>(null)

  useEffect(() => {
    if (isAuthenticated) {
      navigate(currentPath === null || currentPath === '' ? '/' : currentPath)
    }
  }, [isAuthenticated])

  const [errrorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmedPassword: '',
    phoneNumber: '',
  })

  const handleSubmit = async (values: any) => {
    const form = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      password: values.password,
      phoneNumber: values.phoneNumber,
      userType: 'Traveler',
    }

    await axios.post(process.env.SERVER_HOST + '/api/user/sign_up', form)
    navigate('/sign_in')
  }

  type E164Number = `+${string}${number}`

  function handelInputChange(
    newValue: string | E164Number,
    name: string
  ): void {
    const new_input_values = JSON.parse(JSON.stringify(inputValues))

    new_input_values[name] = newValue

    setInputValues(new_input_values)
  }

  return (
    <div className="app-container">
      <div className="auth-container">
        <Col md="12">
          <Form
            innerRef={formRef}
            initialValues={inputValues}
            validationSchema={yup.object().shape({
              emailAddress: yup
                .string()
                .isRequired(true, '이메일 주소를 입력하세요.'),
              firstName: yup
                .string()
                .isRequired(true, '이름이 어떻게 되시나요.'),
              lastName: yup
                .string()
                .isRequired(true, '성씨가 어떻게 되시나요.'),
              password: yup.string().isRequired(true, '비밀번호를 입력하세요.'),
              confirmedPassword: yup
                .string()
                .isRequired(true, '비밀번호를 다시 한번 입력하세요.'),
              phoneNumber: yup
                .string()
                .isRequired(true, '전화번호를 입력하세요.'),
            })}
            onSubmit={handleSubmit}
          >
            <div className="auth-form-content">
              <h4 className="auth-form-title mt-3">회원가입</h4>
              <div className="form-group mt-3">
                <label htmlFor="emailAddress">이메일</label>
                <Field
                  name="emailAddress"
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'emailAddress')
                  }
                  validate={(value) => {
                    const email_pattern =
                      /^[A-Z0-9\._%-]+@[A-Z0-9\.-]+\.[A-Z]{2,4}(?:[;][A-Z0-9\._%-]+@[A-Z0-9\.-]+\.[A-Z]{2,4})*$/i

                    if (!email_pattern.test(value)) {
                      return '이메일이 유효하지 않습니다.'
                    }
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="firstName">이름</label>
                <Field
                  name="firstName"
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter first name"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'firstName')
                  }
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="lastName">성</label>
                <Field
                  name="lastName"
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter last name"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'lastName')
                  }
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="phoneNumber">전화번호</label>
                <PhoneInput
                  placeholder="Enter Phone Number"
                  onChange={(newValue) => {
                    formRef.current.setFieldTouched('phoneNumber')
                    formRef.current.setFieldValue('phoneNumber', newValue ?? '')

                    return handelInputChange(newValue ?? '', 'phoneNumber')
                  }}
                />
                <Field
                  name="phoneNumber"
                  type="hidden"
                  className="form-control mt-1"
                  innerRef={inputRef}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password">비밀번호</label>
                <Field
                  name="password"
                  autoComplete="off"
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'password')
                  }
                  validate={(value) => {
                    const special_chars =
                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

                    if (
                      !special_chars.test(value) ||
                      !value.match(/[A-Z]/) ||
                      value.length < 6 ||
                      !value.match(/[a-z]/) ||
                      !value.match(/\d/)
                    ) {
                      return '비밀번호가 단순합니다. 6자 이상의 영문 대/소문자, 숫자 및 특수문자 조합으로 입력하셔야 합니다.'
                    }
                  }}
                />
                <PasswordStrengthChecker password={inputValues.password} />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="confirm">비밀번호 확인</label>
                <Field
                  name="confirmedPassword"
                  autoComplete="off"
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'confirmedPassword')
                  }
                  validate={(value) => {
                    if (value !== inputValues.password) {
                      return '비밀번호가 일치하지 않습니다.'
                    }
                  }}
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="uri-travel-btn btn">
                  가입하기
                </button>
              </div>
              <p className="forgot-password text-center mt-2">
                이미 회원이세요? <a href="/sign_in">로그인 하기</a>
              </p>
            </div>
          </Form>
        </Col>
      </div>
    </div>
  )
}
