import React from 'react'
import { connect } from 'react-redux'
import * as actions from './actions'
import { Row, Col } from 'reactstrap'
import {
  FilterClassStateType,
  SelectedOptionsType,
  FilterSelectedStateType,
  ReduxState,
  MapStateToProps,
} from './types'
import { SearchFilter } from './tour_management_components'
import TourManagementAddModal from './tour_management_add_modal'

const mapStateToProps = (state: ReduxState): MapStateToProps => {
  return {
    filter_options: state.filter_settings,
  }
}

const mapDispatchToProps = (dispatch: any): Dispatch => {
  return {
    setFilterSelected: (filter_selected: FilterSelectedStateType) =>
      dispatch(actions.setFilterSelected(filter_selected)),
  }
}

type Dispatch = {
  setFilterSelected: (selected: FilterSelectedStateType) => {
    type: string
    payload: FilterSelectedStateType
  }
}

type ComponentProps = MapStateToProps & Dispatch & {}

class TourManagmentFilterClass extends React.Component<
  ComponentProps,
  FilterClassStateType
> {
  constructor(props: ComponentProps) {
    super(props)
    this.state = {
      dd_options: {
        country: [{ label: 'Canada', value: 'Canada' }],
        province: [{ label: 'British Columiba', value: 'BC' }],
        city: [
          { label: 'Vancouver', value: 'Vancouver' },
          { label: 'North Vancouver', value: 'North Vancouver' },
          { label: 'Burnaby', value: 'Burnaby' },
        ],
        guide: [
          { label: 'Seungchan Yang (scyang)', value: 234 },
          { label: 'Dongjo Suh (jsuh)', value: 425 },
        ],
      },

      input_values: {},

      add_modal_open: false,

      loader_options: {
        loading: false,
        spinner: <div />,
        background_style: {
          borderRadius: '0.25rem',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        message_style: { color: '#000' },
      },
    }

    this.handleFilter = this.handleFilter.bind(this)
    this.handleOpenAddTourModal = this.handleOpenAddTourModal.bind(this)
    this.handleCloseAddTourModal = this.handleCloseAddTourModal.bind(this)
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (
      this.props &&
      this.props.filter_options &&
      prevProps &&
      prevProps.filter_options &&
      JSON.stringify(prevProps.filter_options) !==
        JSON.stringify(this.props.filter_options)
    ) {
      this.setState({
        dd_options: this.props.filter_options,
        loader_options: { ...this.state.loader_options, loading: false },
      })
    }
  }

  handleFilter(selected_options: SelectedOptionsType) {
    const selectedOptions: FilterSelectedStateType = {}
    selectedOptions.options = { ...selected_options.filter_params }
    selectedOptions.ready_for_filter = true

    this.props.setFilterSelected(selectedOptions)
  }

  handleOpenAddTourModal() {
    this.setState({ add_modal_open: true })
  }

  handleCloseAddTourModal() {
    this.setState({ add_modal_open: false })
  }

  render() {
    const { dd_options } = this.state

    let search_filter: JSX.Element | string = ''

    if (Object.keys(dd_options).length > 0) {
      search_filter = (
        <SearchFilter
          filterTitile={'Tour Schedules Management'}
          filterSettings={dd_options}
          handleFilterSearch={this.handleFilter}
          filterPerRow={4}
        />
      )
    }

    const add_template_btn = (
      <button
        type="button"
        className="btn btn-outline-primary float-right"
        onClick={this.handleOpenAddTourModal}
        id={'open-tour-modal-btn'}
      >
        <i className="zmdi zmdi-edit zmdi-hc-lg"> </i>&nbsp;&nbsp; Add New Tour
      </button>
    )

    let add_modal: JSX.Element | string = ''
    if (this.state.add_modal_open) {
      add_modal = (
        <TourManagementAddModal
          is_open={this.state.add_modal_open}
          handleModalClose={this.handleCloseAddTourModal}
        />
      )
    }

    /* if(permission_flags && typeof permission_flags.add_letter_template !== 'undefined' && permission_flags.add_letter_template === true)
        {
            add_template_btn =<button className="btn btn-outline-primary float-right"
                                    onClick={vm.handleAddTemplate}
                                    id={'btn_add_letter_template'}
                            >
                                <i className="zmdi zmdi-edit zmdi-hc-lg"> </i>&nbsp;&nbsp; Add Letter Template
                            </button>;
        }

        if (vm.state.add_modal_open) {
            add_modal = <AddContractsLetterTemplate
                is_open={vm.state.add_modal_open}
                handle_modal_close={vm.handleCloseAddTemplate}
            />;
        }*/

    return (
      <div className="container-fluid mt-3">
        <Row className="pb-4">
          <Col xs="10">
            <h4>Tour Schedules Management</h4>
          </Col>
          <Col xs="2" className="text-right">
            {add_template_btn}
          </Col>
        </Row>
        {search_filter}
        {add_modal}
      </div>
    )
  }
}

const TourManagmentFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(TourManagmentFilterClass)
export default TourManagmentFilter
