import React from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'
import TourManagmentFilter from './tour_management_filter'

import './../../sass/tour_management/tour_management.scss'

const store = createStore(reducers)

class TourManagmentMainClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {}
  }

  /* componentDidMount() {
        const vm = this;

        let url = "/contract_management/temporary_instructor_management/letter_templates/get_letter_template_settings";

        axios.get(url)
            .then((response) => {
                batch(() => {
                    store.dispatch(actions.setFilterOptions(response.data.drop_downs));
                    store.dispatch(actions.setTooltips(response.data.tooltips));
                    store.dispatch(actions.setPermissionFlags(response.data.flags));
                })
            })
            .catch((err) => {
                default_alert(err);
            })
    }
*/

  render() {
    return (
      <div className="app-container">
        <Provider store={store}>
          <TourManagmentFilter />
        </Provider>
      </div>
    )
  }
}

export default TourManagmentMainClass
