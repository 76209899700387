import React from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'
import { withUseParamsHOC } from './../../../common/system/route/route'
import {
  ConfirmationModal,
  tourBasicInfo,
  tourDetailedInfo,
  tourHeaderImages,
  tourSchedulesInfo,
} from './../../tour_package/tour_package_components'

import axios from 'axios'
import { default_alert } from './../../../common/system/alert/alert'

import './../../../sass/tour_package/tour_package.scss'
import { ScheduleInfo } from './types'
import { OrderSummary } from './common_function'

class MyTourProfileClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      order_info: {},
      currency_info: {},

      confirm_modal_settings: {
        is_open: false,
        input_values: {},
      },
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount() {
    const vm = this

    if (vm.props.isAuthenticated) {
      vm.props.setCurrentPath('/my_tour/' + vm.props.router.params.id)

      vm.fetchData()
    } else {
      default_alert('요청하신 페이지는 찾을수 없습니다.')
    }
  }

  fetchData() {
    const vm = this

    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

    const order_id = vm.props.router.params.id

    fetch(process.env.SERVER_HOST + '/api/order/info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ order_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Object.keys(data.order_info).length === 0) {
          default_alert(
            '요청하신 자료를 찾을수 없습니다. 불편을 드려서 죄송합니다.'
          )
        } else {
          vm.setState({
            order_info: data.order_info,
            currency_info: data.exchange_rate,
          })
        }
      })
      .catch((err) => {
        default_alert(
          ' 요청하신 자료를 찾을수 없습니다. 불편을 드려서 죄송합니다. ' + err
        )
      })
  }

  handleCloseModal(ev: React.MouseEvent<HTMLElement>) {
    const vm = this

    vm.setState({
      confirm_modal_settings: {
        is_open: false,
        input_values: {},
      },
    })
  }

  render(): React.ReactNode {
    const vm = this

    const { confirm_modal_settings, order_info, currency_info } = vm.state

    let country = ''
    let city = ''
    let tour_title = ''

    let header_iamges: string | JSX.Element = ''
    let basic_info: string | JSX.Element = ''
    let details: string | JSX.Element = ''

    let tour_schedules: string | JSX.Element = ''

    let tour_inqury_form: string | JSX.Element = ''

    let confirmation_modal: string | JSX.Element = ''

    if (Object.keys(order_info).length > 0) {
      const tour_info = order_info.information

      const schedules_info: Array<ScheduleInfo> = tour_info.stops

      country = order_info.country
      city = order_info.location
      tour_title = tour_info.title

      header_iamges = tourHeaderImages(order_info.productPhoto)
      basic_info = tourBasicInfo(
        tour_info.title,
        tour_info.description,
        order_info.rating,
        order_info.price,
        order_info.currency,
        tour_info.duration,
        tour_info.maxCapacity,
        currency_info.value
      )
      tour_schedules = tourSchedulesInfo(schedules_info)
      details = tourDetailedInfo(
        tour_info.meetingLocation,
        tour_info.tourStarttime,
        tour_info.included,
        tour_info.notIncluded,
        tour_info.extraInformation,
        tour_info.cancellationPolicy
      )

      tour_inqury_form = (
        <OrderSummary
          guide_id={order_info.seller._id}
          guide={order_info.seller.profile}
          tour={{
            price: order_info.price,
            currency: order_info.currency,
            duration: tour_info.duration,
            capacity: tour_info.maxCapacity,
            customized: tour_info.customized,
          }}
          payment_info={{
            price: order_info.price,
            currency: order_info.currency,
          }}
          order_id={order_info?._id ?? ''}
          payment_history={{
            status: order_info?.status ?? null,
            payment_info: order_info?.paymentInformation ?? null,
          }}
          exchange_rate={currency_info.value}
        />
      )

      if (confirm_modal_settings.is_open) {
        confirmation_modal = (
          <ConfirmationModal
            is_open={confirm_modal_settings.is_open}
            handleClose={vm.handleCloseModal}
            guide_info={order_info.seller.profile}
            tour_data={{
              title: tour_info.title,
              price: order_info.price,
              currency: order_info.currency,
              duration: tour_info.duration,
              capacity: tour_info.maxCapacity,
              customized: tour_info.customized,
            }}
            customer_info={confirm_modal_settings.input_values}
            exchange_rate={currency_info.value}
          />
        )
      }
    }

    return (
      <React.Fragment>
        <Row className="app-container mt-3">
          <Col md="12">
            <Breadcrumb>
              <BreadcrumbItem>{country}</BreadcrumbItem>
              <BreadcrumbItem>{city}</BreadcrumbItem>
              <BreadcrumbItem active>{tour_title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col md="12" className="mb-3">
            {header_iamges}
          </Col>

          <Col lg="9" md="8">
            {basic_info}
            <hr />
            {tour_schedules}
            <hr />
            {details}
          </Col>

          <Col id="inqury" lg="3" md="4">
            {tour_inqury_form}
          </Col>
        </Row>
        {confirmation_modal}
      </React.Fragment>
    )
  }
}

const MyTourProfile = MyTourProfileClass

export default withUseParamsHOC(MyTourProfile)
