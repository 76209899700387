// ReviewForm.tsx

import React, { useEffect, useState } from 'react'
import StarRating from './start_rating'
import { Input } from 'reactstrap'
import axios from 'axios'

export interface ReviewFormProps {
  comment: string
  orderId: string
  rating: number
  reviewId: string
  onClose: (reload: boolean) => void
}

export interface ReviewFormData {
  comment: string
  rating: number
  orderId: string
  reviewId: string
}

export const ReviewForm: React.FC<ReviewFormProps> = (
  props: ReviewFormProps
) => {
  const [name, setName] = useState('')
  const [comment, setComment] = useState(props.comment)
  const [rating, setRating] = useState(props.rating)

  useEffect(() => {
    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )

    if (user_info) {
      setName(user_info.lastName + user_info.firstName)
    }
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const url = process.env.SERVER_HOST + '/api/review/create'

    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )

    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''
    axios
      .post(
        url,
        {
          user_id: user_info?.uid,
          comment: comment,
          rating: rating,
          order_id: props.orderId,
          reviewId: props.reviewId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        props.onClose(true)
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <h6>{name} 님 소중한 의견 감사드립니다.</h6>
      </div>
      <div className="mb-2 text-center">
        <StarRating rating={rating} onChange={setRating} />
      </div>
      <div className="mb-3">
        <Input
          type="textarea"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="text-right border-top pt-3">
        <button className="uri-travel-btn btn" type="submit">
          {props.reviewId && props.reviewId.length > 0 ? '수정' : '제출'}
        </button>
        <button
          onClick={() => props.onClose(false)}
          className="btn btn-secondary ml-2"
          type="button"
        >
          취소
        </button>
      </div>
    </form>
  )
}
