import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleInputChange } from 'react-select/dist/declarations/src/utils'
import { Alert, Col } from 'reactstrap'
import { default_alert } from '../../common/system/alert/alert'

export default function SignIn(props: {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticated: boolean
  currentPath: string
}) {
  const { currentPath, isAuthenticated, setIsAuthenticated } = props
  const [keepLogin, setKeepLogin] = useState<boolean>(false)

  useEffect(() => {
    if (isAuthenticated) {
      navigate(currentPath === null || currentPath === '' ? '/' : currentPath)
    }
  }, [isAuthenticated])

  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('')

  const navigate = useNavigate()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    const value = target.checked

    setKeepLogin(value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const form = {
      emailAddress: formData.get('emailAddress'),
      password: formData.get('password'),
    }
    const { data } = await axios.post(
      process.env.SERVER_HOST + '/api/user/sign_in',
      form
    )

    if (data.status === parseInt('401')) {
      setErrorMessage(
        <Col md="12 mt-3">
          <Alert color="danger">{data.response}</Alert>
        </Col>
      )
    } else {
      setErrorMessage('')

      // if keep login, data store in local storage
      // to keep data event though browser close
      // otherwise, store data in session storage
      if (keepLogin) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('user', JSON.stringify(data.user_info))
      } else {
        sessionStorage.setItem('token', data.token)
        sessionStorage.setItem('user', JSON.stringify(data.user_info))
      }

      localStorage.setItem('keepLogin', keepLogin ? 'Yes' : 'No')

      setIsAuthenticated(true)
    }
  }

  return (
    <div className="app-container">
      <div className="auth-container">
        {errorMessage}
        <Col md="12">
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="auth-form-content">
              <h4 className="auth-form-title mt-3">로그인</h4>
              <div className="form-group mt-3">
                <label htmlFor="emailAddress">이메일</label>
                <input
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  name="emailAddress"
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password">비밀번호</label>
                <input
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  name="password"
                />
              </div>
              <div className="form-check mt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(e) => handleInputChange(e)}
                  name="keepLogin"
                  checked={keepLogin}
                />
                <label className="form-check-label" htmlFor="keepLogin">
                  로그인 유지
                </label>
              </div>
              {keepLogin ? (
                <div className="form-group mt-1">
                  <p style={{ fontSize: '12px' }}>
                    이 확인란을 선택하면 계정에서 자동으로 로그아웃될 때까지 이
                    기기에서 로그인 상태가 유지됩니다. 공유 기기에서는 이
                    확인란을 선택하지 마십시오.
                  </p>
                </div>
              ) : (
                ''
              )}
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="uri-travel-btn btn">
                  이메일로 로그인
                </button>
              </div>
              <p className="forgot-password text-center mt-4">
                <a href="/forgot_password">비밀번호를 잊으셨나요?</a>
              </p>
              <p className="forgot-password text-center mt-2">
                계정이 없으세요? <a href="/sign_up">회원가입하기</a>
              </p>
            </div>
          </form>
        </Col>
      </div>
    </div>
  )
}
