import React from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'
import { withUseParamsHOC } from '../../common/system/route/route'
import {
  ConfirmationModal,
  tourBasicInfo,
  tourDetailedInfo,
  tourHeaderImages,
  TourInquiryForm,
  tourSchedulesInfo,
} from './tour_package_components'

import axios from 'axios'
import { default_alert } from '../../common/system/alert/alert'

import './../../sass/tour_package/tour_package.scss'
import { ScheduleInfo, TravelInquiryInputs } from './types'

class TourPackageClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      tour_data: {},
      currency_info: {},

      confirm_modal_settings: {
        is_open: false,
        input_values: {},
      },
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    const vm = this

    vm.props.setCurrentPath('/tour_package/' + vm.props.router.params.id)

    vm.fetchData()
  }

  fetchData() {
    const vm = this

    const url =
      process.env.SERVER_HOST + '/inventory/tour/' + vm.props.router.params.id

    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        const response_data = response.data

        const vm = this

        const states_to_update: any = {}
        if (Object.keys(response_data).length > 0) {
          states_to_update.tour_data = response_data.tour_data
          //states_to_update.order_info = response_data.order_info
          states_to_update.currency_info = response_data.currency
        }

        vm.setState(states_to_update)
      })
  }

  handleFormSubmit(values: TravelInquiryInputs, actions: any) {
    const vm = this

    const url = process.env.SERVER_HOST + '/inventory/tour/inquiry'
    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

    const payload = { inquiry_form: values, tour_id: vm.props.router.params.id }

    axios
      .post(url, payload, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        const response_data = response.data

        if (response_data.result) {
          vm.setState(
            {
              confirm_modal_settings: {
                is_open: true,
                input_values: values,
              },
            },
            () => {
              actions.resetForm()
            }
          )
        } else {
          default_alert(
            '고객님께서 보내신 문의 메일이 전송되지 못했습니다. 이용중 불편을 드려서 죄송합니다.'
          )
        }
      })
      .catch((err) => {
        default_alert(
          '고객님께서 보내신 문의 메일이 전송되지 못했습니다. 이용중 불편을 드려서 죄송합니다.'
        )
      })
  }

  handleCloseModal(ev: React.MouseEvent<HTMLElement>) {
    const vm = this

    vm.setState({
      confirm_modal_settings: {
        is_open: false,
        input_values: {},
      },
    })
  }

  render(): React.ReactNode {
    const vm = this

    const { tour_data, confirm_modal_settings, order_info, currency_info } =
      vm.state

    let country = ''
    let city = ''
    let tour_title = ''

    let header_iamges: string | JSX.Element = ''
    let basic_info: string | JSX.Element = ''
    let details: string | JSX.Element = ''

    let tour_schedules: string | JSX.Element = ''

    let tour_inqury_form: string | JSX.Element = ''

    let confirmation_modal: string | JSX.Element = ''

    if (Object.keys(tour_data).length > 0) {
      const tour_info = tour_data.information

      const schedules_info: Array<ScheduleInfo> = tour_info.stops

      country = tour_data.country
      city = tour_data.location
      tour_title = tour_info.title

      header_iamges = tourHeaderImages(tour_data.productPhoto)
      basic_info = tourBasicInfo(
        tour_info.title,
        tour_info.description,
        tour_data.rating,
        tour_data.price,
        tour_data.currency,
        tour_info.duration,
        tour_info.maxCapacity,
        currency_info.value
      )
      tour_schedules = tourSchedulesInfo(schedules_info)
      details = tourDetailedInfo(
        tour_info.meetingLocation,
        tour_info.tourStarttime,
        tour_info.included,
        tour_info.notIncluded,
        tour_info.extraInformation,
        tour_info.cancellationPolicy
      )

      tour_inqury_form = (
        <TourInquiryForm
          guide_id={tour_data.user._id}
          guide={tour_data.user.profile}
          tour={{
            price: tour_data.price,
            currency: tour_data.currency,
            duration: tour_info.duration,
            capacity: tour_info.maxCapacity,
            customized: tour_info.customized,
            productType: tour_data.productType,
          }}
          handleFormSubmit={vm.handleFormSubmit}
          exchange_rate={currency_info.value}
        />
      )

      if (confirm_modal_settings.is_open) {
        confirmation_modal = (
          <ConfirmationModal
            is_open={confirm_modal_settings.is_open}
            handleClose={vm.handleCloseModal}
            guide_info={tour_data.user.profile}
            tour_data={{
              title: tour_info.title,
              price: tour_data.price,
              currency: tour_data.currency,
              duration: tour_info.duration,
              capacity: tour_info.maxCapacity,
              customized: tour_info.customized,
            }}
            customer_info={confirm_modal_settings.input_values}
            exchange_rate={currency_info.value}
          />
        )
      }
    }

    return (
      <React.Fragment>
        <Row className="app-container mt-3">
          <Col md="12">
            <Breadcrumb>
              <BreadcrumbItem>{country}</BreadcrumbItem>
              <BreadcrumbItem>{city}</BreadcrumbItem>
              <BreadcrumbItem active>{tour_title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col md="12" className="mb-3">
            {header_iamges}
          </Col>

          <div className="is-tablet-mobile request-btn">
            {vm.props.isAuthenticated ? (
              <a href="#inqury">
                <div className="w-100 uri-travel-btn btn">문의하기</div>
              </a>
            ) : (
              <a
                href="/sign_in"
                type="button"
                className="w-100 uri-travel-btn btn"
              >
                문의하기
              </a>
            )}
          </div>

          <Col lg="9" md="8">
            {basic_info}
            <hr />
            {tour_schedules}
            <hr />
            {details}
          </Col>

          <Col id="inqury" lg="3" md="4">
            {tour_inqury_form}
          </Col>
        </Row>
        {confirmation_modal}
      </React.Fragment>
    )
  }
}

const TourPackage = TourPackageClass

export default withUseParamsHOC(TourPackage)
