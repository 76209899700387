import React from 'react'
import { Label } from 'reactstrap'

type FileUploadProps = {
  visible: boolean
  accept: string
  multiple: boolean
  triggerDialog?: React.RefObject<HTMLInputElement>
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    id: string
  ) => void
  name?: string
  id?: string
}

/**
 * generate a html to open file browse
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 */
export const FileUploadZone = (props: FileUploadProps): JSX.Element => {
  return (
    <div
      className="p-5 file-drop-zone"
      style={{ display: props.visible ? 'block' : 'none' }}
    >
      <div className="d-flex file-drop-zone-title">
        <div className="mr-1">
          <strong>Drag and Drop File Here or </strong>
        </div>
        <input
          type="file"
          id={props.id ?? 'file_to_upload_input'}
          name={props.name ?? 'file_to_upload_input[]'}
          className="d-none"
          accept={props.accept}
          multiple={props.multiple}
          ref={
            typeof props.triggerDialog !== 'undefined'
              ? props.triggerDialog
              : null
          }
          onChange={(e) =>
            props.handleChange(e, 'choose_file', props.id ?? 'file')
          }
        />
        <Label
          for={props.id ?? 'file_to_upload_input'}
          className="upload_file_label"
          style={{ color: '#4285f4', cursor: 'pointer' }}
        >
          <span>Select File to Upload</span>
        </Label>
      </div>
    </div>
  )
}
