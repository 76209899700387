import React, { useEffect, useRef, useState } from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

import './../../../sass/user/payment.scss'
import { OrderDetail, ProductDetail } from './common_function'
import { ExchangeRate, Order } from './types'
import axios from 'axios'
import { default_alert } from '../../../common/system/alert/alert'

// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.STRIPE_KEY ?? '')

export default function Payment(props: any) {
  const [clientSecret, setClientSecret] = useState('')
  const [orderInfo, setOrderInfo] = useState<Order | null>(null)
  const [exchangeRate, setExchangeRate] = useState<ExchangeRate | null>(null)
  const hasFetchedData = useRef(false)

  const token =
    localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

  const { order_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    async function fetchData() {
      await fetch(
        process.env.SERVER_HOST + '/api/order/create-payment-intent',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ items: [{ id: order_id }] }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.client_secret)
          setOrderInfo(data.order_info)
          setExchangeRate(data.exchange_rate.value)
        })
    }

    if (hasFetchedData.current === false) {
      fetchData()
      hasFetchedData.current = true
    }
  }, [])

  const appearance = {
    theme: 'stripe',
  }
  const options: any = {
    clientSecret,
    appearance,
  }

  const bypassPaymentCompletion = async () => {
    const url = process.env.SERVER_HOST + '/api/order/completion'

    const token =
      localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

    await axios
      .post(
        url,
        { order_id: order_id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        navigate('/payment_completion/' + order_id)
      })
      .catch((err) => {
        default_alert(err)
      })
  }

  return (
    <div className="app-container mt-3">
      <Row>
        <Col md={5} className="product p-4 payment-page-panel">
          <h5 className="fw-bold mb-2 text-center">투어 정보</h5>
          <div className="border-top pt-3 mb-2">
            {
              <ProductDetail
                information={orderInfo?.information ?? null}
                guide={orderInfo?.seller?.profile?.displayName ?? ''}
              />
            }
          </div>
          <div className="border-top pt-3">
            <p className="mb-0 pb-0 fw-bold">환불 정책:</p>
            <p className="mb-0 pb-0">
              여행 출발일(D-Day) 30일 전 취소 시: 예약금 전액 환불
            </p>
            <p className="mb-0 pb-0">
              여행 출발일(D-Day) 15일 ~ 29일 전 취소 시: 총 여행 비용의 70% 환불
            </p>
            <p className="mb-0 pb-0">
              여행 출발일(D-Day) 14일 ~ 7일 전 취소 시: 총 여행 비용의 50% 환불
            </p>
            <p className="mb-0 pb-0">
              여행 출발일(D-Day) 7일 이내 취소 시: 총 여행 비용의 20% 환불
            </p>
            <p>투어 도시의 현지 시간 기준입니다.</p>
          </div>
        </Col>
        <Col md={2}></Col>
        <Col md={5} className="order">
          <Row>
            <Col md={12} className="payment-detail p-4 payment-page-panel mb-4">
              {orderInfo ? (
                <OrderDetail
                  price={orderInfo.price}
                  currency={orderInfo.currency}
                  exchangeRate={exchangeRate}
                />
              ) : (
                ''
              )}
            </Col>
            <Col md={12} className="payment-method p-4 payment-page-panel">
              <div className="App">
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm order_id={order_id ?? ''} />
                  </Elements>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

function CheckoutForm(props: { order_id: string }) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: any) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: '/payment_completion/' + props.order_id,
      },
      redirect: 'if_required',
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    /*if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? '')
    } else if() {
      setMessage('An unexpected error occurred.')
    }*/

    if (response.error) {
      const error = response.error

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message ?? '')
      } else {
        setMessage('An unexpected error occurred.')
      }
    } else {
      const url = process.env.SERVER_HOST + '/api/order/completion'

      const token =
        localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

      await axios
        .post(
          url,
          { order_id: props.order_id },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          navigate('/payment_completion/' + props.order_id)
        })
        .catch((err) => {
          default_alert(err)
        })
    }

    setIsLoading(false)
  }

  const [agreement, setAgreement] = useState(false)

  const handleCheckBoxChange = (ev: any) => {
    setAgreement(ev.target.checked)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="mt-3">
        <FormGroup check>
          <Input type="checkbox" onChange={(ev) => handleCheckBoxChange(ev)} />
          <Label check>
            본인은 여행자{' '}
            <a href="/user_terms_of_service" target="_blank">
              이용약관
            </a>
            을 읽었고 이에 동의 합니다.
          </Label>
        </FormGroup>
      </div>

      <button
        disabled={isLoading || !stripe || !elements || !agreement}
        id=""
        className="btn uri-travel-btn mt-3 mr-3"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            '결제 하기'
          )}
        </span>
      </button>
      <a href="/my_tour" type="button" className="btn btn-secondary mt-3">
        취소 하기
      </a>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
