import { IntialStateType, ActionStateType } from './types'

const initialState = {
  filter_settings: {}, // Filter options for search_filter panel
  filter_selected: {
    ready_for_filter: false,
    options: {},
    timestamp: 0,
  }, // Selected options for search_filter panel
}

const reducers = (
  state: IntialStateType = initialState,
  action: ActionStateType
) => {
  switch (action.type) {
    case 'SET_FILTER_OPTIONS':
      return { ...state, filter_settings: action.payload }

    case 'SET_FILTER_SELECTED':
      return {
        ...state,
        filter_selected: {
          ready_for_filter: action.payload.ready_for_filter,
          options: { ...action.payload.options },
          selected_term: action.payload,
          timestamp: Math.floor(Date.now() / 1000),
        },
      }
    default:
      return state
  }
}

export default reducers
