import React from 'react'

type Props = {
  handleDrop: (e: DragEvent, type: string, id: string) => void
  id?: string
  children?: JSX.Element
}

type States = {
  dragging: boolean
}

export class DragAndDrop extends React.Component<Props, States> {
  private dragCounter = 0
  private dropRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      dragging: false,
    }
  }

  handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    this.dragCounter++
    if (
      e.dataTransfer &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0
    ) {
      this.setState({ dragging: true })
    }
  }
  handleDragOut = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ dragging: false })
    }
  }
  handleDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ dragging: false })
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      this.props.handleDrop(e, 'drag_drop', this.props.id ?? 'file')
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }
  componentDidMount() {
    const div = this.dropRef.current

    if (div) {
      div.addEventListener('dragenter', this.handleDragIn)
      div.addEventListener('dragleave', this.handleDragOut)
      div.addEventListener('dragover', this.handleDrag)
      div.addEventListener('drop', this.handleDrop)
    }
  }
  componentWillUnmount() {
    const div = this.dropRef.current
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn)
      div.removeEventListener('dragleave', this.handleDragOut)
      div.removeEventListener('dragover', this.handleDrag)
      div.removeEventListener('drop', this.handleDrop)
    }
  }
  render() {
    return (
      <div className="file-drop-zone-overlay-area" ref={this.dropRef}>
        {this.state.dragging && (
          <div className="show-overlay">
            <div className="overlay-title">
              <div>Drag and Drop File Here</div>
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}
