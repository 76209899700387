import React from 'react'
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'

export const OrderSummary = (props: {
  guide_id: string
  guide: {
    displayName: string
    profilePicture: BinaryData
    rating: {
      star: number
      count: number
    }
  }
  tour: {
    price: number
    currency: string
    duration: number
    capacity: number
    customized: boolean
  }
  payment_info: {
    price: number
    currency: string
  }
  order_id: string
  payment_history: {
    status: string
    payment_info: {
      paymentMethod: string
      transactionId: string
      createdAt: any
      paidAmount: string
      paidCurrency: string
      exchangeAmount: string
      exchangeCurrency: string
    }
  }

  exchange_rate: {
    CAD: number
    EUR: number
    USD: number
    WON: number
  }
}) => {
  const { guide, tour, payment_history, payment_info } = props

  const name = guide.displayName
  const rate_tmp = (guide.rating.star / guide.rating.count).toFixed(2)
  let price: string | number = tour.price
  let currency = tour.currency

  let exchange_price_info: JSX.Element = (
    <React.Fragment>
      &#8361;{(price * props.exchange_rate.WON).toLocaleString('en') + ' WON'}
    </React.Fragment>
  )

  if (payment_history.status === 'Pending') {
    price = payment_info.price
    currency = payment_info.currency

    exchange_price_info = (
      <React.Fragment>
        &#8361;{(price * props.exchange_rate.WON).toLocaleString('en') + ' WON'}
      </React.Fragment>
    )
  } else if (
    payment_history.status === 'Approved' ||
    payment_history.status === 'Completed'
  ) {
    price = payment_history.payment_info.paidAmount
    currency = payment_history.payment_info.paidCurrency

    let currency_mark: string | JSX.Element = '$'
    if (
      payment_history.payment_info.exchangeCurrency === 'WON' ||
      payment_history.payment_info.exchangeCurrency === 'WON'
    ) {
      currency_mark = <span>&#8361;</span>
    } else if (payment_history.payment_info.exchangeCurrency === 'EUR') {
      currency_mark = <span>&#8364;</span>
    }

    exchange_price_info = (
      <React.Fragment>
        {currency_mark}
        {Number(payment_history.payment_info.exchangeAmount).toLocaleString(
          'en'
        ) +
          ' ' +
          payment_history.payment_info.exchangeCurrency}
      </React.Fragment>
    )
  }

  const cost = '$' + price.toLocaleString('en') + ' ' + tour.currency
  const people = '최대 ' + tour.capacity + '인'
  const tour_time = tour.duration + '시간'
  const tour_customized = tour.customized
  let rate: JSX.Element | string = ''

  if (!isNaN(parseInt(rate_tmp))) {
    rate = (
      <React.Fragment>
        <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
      </React.Fragment>
    )
  }

  let form: string | JSX.Element = ''

  const user_info = JSON.parse(
    localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
  )

  if (payment_history.status === 'Approved') {
    form = (
      <Col md="12" className="mt-2">
        <Row>
          <Col md="12">
            <h6>
              <span className="fw-bold">여행객: </span>
              {user_info.lastName + user_info.firstName + '님'}
            </h6>
          </Col>
          <Col md="12" className="mt-2">
            <h6 className="fw-bold">결제</h6>
          </Col>
          <Col md="12">
            <Row>
              <Col md="8" sm="8" xs="8">
                {payment_history.payment_info.paymentMethod}{' '}
                {'****' +
                  payment_history.payment_info.transactionId.substring(-1, 4)}
              </Col>
              <Col md="4" sm="4" xs="4" className="text-right">
                {payment_history.payment_info.paidAmount}{' '}
                {payment_history.payment_info.paidCurrency}
              </Col>
              <Col md="12" className="border-bottom pb-1">
                {new Date(payment_history.payment_info.createdAt).toString()}
              </Col>
              <Col md="8" sm="8" xs="8" className="pt-1">
                결제 금액:
              </Col>
              <Col md="4" sm="4" xs="4" className="text-right pt-1">
                {payment_history.payment_info.paidAmount}{' '}
                {payment_history.payment_info.paidCurrency}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  } else if (payment_history.status === 'Pending') {
    form = (
      <Col md="12">
        <Row>
          <Col md="12">
            <h6>
              <span className="fw-bold">여행객: </span>
              {user_info.lastName + user_info.firstName + '님'}
            </h6>
          </Col>
          <Col md="12" className="mt-2">
            <a
              href={'/checkout/' + props.order_id}
              type="button"
              className="w-100 uri-travel-btn btn"
            >
              결제 하기
            </a>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <Card className="tour-inqury-form">
      <CardBody>
        <Row className="row-center">
          <Col md="5" sm="6">
            <a
              className="tour-guide-link"
              href={'/guide_profile/' + props.guide_id}
            >
              <img
                src={require('./../../../assets/images/' +
                  props.guide.profilePicture)}
                className="figure-img img-fluid rounded"
                alt="profile"
              />
            </a>
          </Col>
          <Col md="7" sm="12">
            <CardTitle tag="h5" className="fw-bold">
              투어 가이드
            </CardTitle>
            <CardSubtitle className="mb-2" tag="h6">
              <a
                className="tour-guide-link"
                href={'/guide_profile/' + props.guide_id}
              >
                {name}
              </a>
            </CardSubtitle>
            <p className="fs-11"> {rate} </p>
          </Col>
          <Col md="7" sm="7" xs="7">
            <h6 className="fw-bold">{exchange_price_info}</h6>
          </Col>
          <Col md="5" sm="5" xs="5" className="text-right">
            <h6 className="fw-bold">{cost}</h6>
          </Col>
          <Col md="7" sm="7" xs="7">
            <h6 className="fw-bold">
              <i className="zmdi zmdi-time"></i>
              &nbsp;{tour_time}
            </h6>
          </Col>
          <Col md="5" sm="5" xs="5" className="text-right">
            <h6 className="fw-bold">
              <i className="zmdi zmdi-accounts"></i>
              &nbsp;{people}
            </h6>
          </Col>
          {form}
        </Row>
      </CardBody>
    </Card>
  )
}
