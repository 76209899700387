import React from 'react'

interface StarRatingProps {
  rating: number
  onChange: (value: number) => void
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onChange }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1)

  const handleClick = (value: number) => {
    onChange(value)
  }

  return (
    <div>
      {stars.map((star) => (
        <button
          className="star-rating"
          key={star}
          type="button"
          onClick={() => handleClick(star)}
        >
          <i
            className={`zmdi zmdi-hc-3x zmdi-star${
              rating >= star ? '' : '-outline'
            }`}
          ></i>
        </button>
      ))}
    </div>
  )
}

export default StarRating
