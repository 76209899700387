import React from 'react'
import { Col, Row } from 'reactstrap'

import './../../../sass/user/terms_of_service.scss'

export default function UserTermsOfService() {
  return (
    <React.Fragment>
      <Row className="app-container">
        <Col md="12">
          <div className="right center mb-4">
            <h3 className="main-theme-text-color">우리트레블 이용약관</h3>
            시행일자: 2013년 4월 1일
          </div>
          <div className="right center mb-3">
            이 이용약관(이하 &#39;약관&#39;)은 우리트래블(이하 &#39;회사&#39;)과
            고객(이하 &#39;이용자&#39;) 간의 여행상품 및 관련 서비스 이용과
            관련한 제반 사항을 규정하는 것을 목적으로 합니다.
          </div>
          <div className="right center mb-3">
            <h6>[이용약관 동의]</h6>
            <ol>
              <li>
                회사가 제공하는 여행상품 및 관련 서비스 이용 시, 이용자는 이
                약관에 동의하는 것으로 간주됩니다.
              </li>
              <li>
                이용자는 약관을 읽고 이에 동의한 후, 여행상품 예약 시 회사가
                요구하는 개인정보를 제공해야 합니다.
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h6>[여행상품 예약]</h6>
            <ol>
              <li>
                이용자는 회사에서 제공하는 여행상품을 선택하여 예약할 수
                있습니다.
              </li>
              <li>
                예약 시, 이용자는 정확한 예약 정보(예: 여행일자, 인원 수, 연락처
                등)를 제공해야 합니다.
              </li>
              <li>
                예약이 완료되면, 이용자는 회사가 제공하는 결제 방법을 통해
                대금을 지불해야 합니다.
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h6>[결제 및 취소]</h6>
            <ol>
              <li>
                회사에서 제공하는 여행상품은 크레딧카드를 통해 결제 가능합니다.
              </li>
              <li>
                예약 취소는 회사에서 제공하는 취소 규정에 따라 처리됩니다. 취소
                규정에 따라 취소 수수료가 부과될 수 있습니다.
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h6>[면책조항]</h6>
            <ol>
              <li>
                회사는 천재지변, 전쟁, 정치적 불안정, 날씨 등 부득이한 사유로
                인해 여행상품 제공을 중단할 수 있습니다.
              </li>
              <li>
                회사는 이용자가 제공한 정보의 부정확성 또는 오류로 인한 손해에
                대해 책임을 지지 않습니다.
              </li>
              <li>
                회사는 이용자가 직접 발생시킨 손해에 대해 책임을 지지 않습니다.
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h6>[재판권 및 준거법]</h6>
            <ol>
              <li>
                이 약관에 대한 관할은 회사의 본사 소재지의 법원으로 합니다.
              </li>
              <li>이 약관에 관한 법령 및 제도는 캐나다법을 적용합니다.</li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h4 className="text-decoration-underline">
              여행상품 환불 세부규정
            </h4>
            <h6>
              환불 규정은 각 예약 상품별로 상이할 수 있습니다. 예약 시 고지된
              환불 규정을 따르며, 일반적으로는 다음과 같은 규정이 적용됩니다:
              <br />
            </h6>
            <ol>
              <li>
                예약 취소: 예약 취소를 하시는 경우, 취소 일자에 따라 환불 금액이
                달라집니다. 일반적으로 출발 일자가 가까워질수록 환불 금액이
                적어집니다. 예약 시 고지된 취소 일자 및 환불 금액을 확인하시기
                바랍니다.
              </li>
              <li>
                불가피한 사유: 예약한 상품의 이용이 불가능한 경우(자연재해,
                정치적 사태, 교통 마비 등)에는 일부 또는 전액 환불이 가능합니다.
                이 경우, 우리트래블은 최대한 빠르게 상황을 파악하고 고객에게
                이를 통보할 것입니다.
              </li>

              <li>
                환불 수수료: 예약 취소 시 발생하는 환불 수수료는 각 상품별로
                상이합니다. 예약 시 고지된 환불 수수료 정보를 확인하시기
                바랍니다.
              </li>
              <li>
                환불 방법: 환불은 예약 시 결제하신 방법과 동일한 방법으로
                처리됩니다. 예를 들어, 신용카드로 결제하셨다면 신용카드로 환불이
                이루어집니다.
              </li>
              <li>
                환불 규정은 우리트래블의 홈페이지나 예약 시 고지되는 사항에서
                확인하실 수 있습니다. 언제든지 궁금한 점이 있으시면 고객센터로
                문의해주시기 바랍니다.
              </li>
            </ol>
            <br />
            <h6>우리트래블의 환불 규정은 다음과 같습니다.</h6>
            <ul>
              <li>여행 출발일(D-Day) 30일 전 취소 시: 예약금 전액 환불</li>
              <li>
                여행 출발일(D-Day) 15일 ~ 29일 전 취소 시: 총 여행 비용의 70%
                환불
              </li>
              <li>
                여행 출발일(D-Day) 14일 ~ 7일 전 취소 시: 총 여행 비용의 50%
                환불
              </li>
              <li>
                여행 출발일(D-Day) 7일 이내 취소 시: 총 여행 비용의 20% 환불
              </li>
            </ul>
            <h6>
              위의 환불 규정은 일반적인 경우에 해당하며, 상황에 따라 변경될 수
              있습니다. 또한, 일부 상품은 환불 규정이 다를 수 있으므로, 예약
              시에는 반드시 해당 상품의 환불 규정을 확인하시기 바랍니다.
            </h6>
          </div>
          <div className="right center mb-3">
            <h4 className="text-decoration-underline">
              여행상품 변경 세부규정
            </h4>
            <h6>우리트래블의 여행상품 변경 규정은 다음과 같습니다.</h6>
            <ul>
              <li>예약 후 7일 이내에 변경 시: 수수료 없이 변경 가능</li>
              <li>
                출발 30일 이상 남은 경우: 변경 수수료 10% (단, 변경 금액이
                100,000원 미만인 경우에는 수수료 면제)
              </li>
              <li>출발 15일 이상 29일 이내인 경우: 변경 수수료 20%</li>
              <li>출발 14일 이내인 경우: 변경 불가</li>
            </ul>
            <h6>
              상품 변경은 예약한 상품과 동일한 여행상품으로만 가능하며, 변경은
              출발 30일 이내에 가능합니다. 상품 변경 시 발생하는 비용은 고객
              부담이며, 변경에 따른 추가 금액이 발생하는 경우, 고객은 해당
              금액을 추가로 지불해야 합니다. 또한, 변경이 불가능한 상품도 있으니
              예약 시에는 반드시 해당 상품의 변경 규정을 확인하시기 바랍니다.
            </h6>
            <br />
            <h6>
              예약한 여행상품에 할당된 가이드를 변경하고자 하는 경우에는,
              우리트래블 예약 담당자에게 변경 요청을 전달하여야 합니다. 가이드
              변경 요청은 최소 7일 전에 알려주시기 바랍니다. 그렇지 않은
              경우에는 변경이 어려울 수 있습니다. 또한, 변경 가능 여부는 가이드
              일정 상황 및 다른 예약 사항에 따라 달라질 수 있습니다. 가이드 변경
              요청 시, 우리트래블 예약 담당자가 해당 가이드의 일정 가능 여부를
              확인하고, 변경이 가능한 경우 새로운 가이드를 배정해드립니다. 이 때
              추가 비용이 발생하지는 않습니다. 그러나, 원하는 가이드가 이미
              예약이 되어있거나, 다른 일정과 충돌이 있어 변경이 불가능할 수도
              있습니다.
            </h6>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}
