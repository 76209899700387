import { FilterSelectedStateType } from './types'

// Initialize filter options for search_filter
export const setFilterOptions = (options: object) => ({
  type: 'SET_FILTER_OPTIONS',
  payload: options,
})

// Set selected options from search_filter to search_result
export const setFilterSelected = (selected: FilterSelectedStateType) => ({
  type: 'SET_FILTER_SELECTED',
  payload: selected,
})
