import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ReviewForm } from './../../../common/system/review/review_form'

interface ReviewModalProps {
  orderId: string
  isOpen: boolean
  comment: string
  rating: number
  reviewId: string
  toggle: (relead: boolean) => void
}

const ReviewModal: React.FC<ReviewModalProps> = ({
  orderId,
  isOpen,
  toggle,
  comment,
  rating,
  reviewId,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle(false)} backdrop="static">
      <ModalHeader toggle={() => toggle(false)}>여행 상품 리뷰</ModalHeader>
      <ModalBody>
        <ReviewForm
          reviewId={reviewId}
          orderId={orderId}
          comment={comment}
          rating={rating}
          onClose={(reload) => toggle(reload)}
        />
      </ModalBody>
    </Modal>
  )
}

export default ReviewModal
