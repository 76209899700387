import React from 'react'
import { Col, Row } from 'reactstrap'

import './../../sass/admin/about.scss'

export default function About() {
  return (
    <React.Fragment>
      <Row className="app-container">
        <Col md="12">
          <div className="center banner"></div>
          <div className="right center mb-3">
            <h3 className="main-theme-text-color">“함께 하는 여행의 추억”</h3>
            여행은 매번 새로운 경험과 추억을 선사합니다. 그리고 이러한 추억은
            일생 동안 기억됩니다. 우리트래블은 이러한 소중한 추억들을 더욱
            특별하게 만들어 드리기 위해 최선을 다하고 있습니다.
            <br />
            우리트래블과 함께하는 여행은 단순한 관광이 아닌, 소중한 추억을
            만들기 위한 여정입니다. 우리는 여행 중에 고객님들이 놓치지 않고 즐길
            수 있는 장소와 숨겨진 보물 같은 스팟을 찾아내고, 고객님들의 취향과
            선호도에 맞추어 맞춤형 여행 상품을 제공합니다.
            <br />
            여행의 추억은 어디에서나 시작됩니다. 그리고 우리트래블은 고객님들이
            보내는 모든 순간을 함께 합니다. 캐나다와 미국의 아름다운 자연 경관과
            문화유산, 신선한 음식과 현지인들의 멋진 이야기까지, 우리트래블은
            고객님들이 누리는 모든 순간을 응원합니다.
            <br />
            우리트래블과 함께하는 여행의 추억을 담은 모든 순간은 늘 특별하고
            소중한 순간이 될 것입니다. 함께 해주세요!!
          </div>
          <div className="right center mb-3">
            <h3 className="main-theme-text-color">우리는 여행중(URI TRAVEL)</h3>
            우리는 여행중(URI TRAVEL)은 캐나다의 아름다운 도시들을 기반으로 개인
            맞춤형 여행상품을 제공하는 여행플랫폼 입니다. 현재는 밴쿠버 맞춤식
            여행상품만 제공하고 있지만, 추후 캐나다 전역과 미국 내 여행상품도
            함께 제공할 예정입니다.
            <br />
            우리트래블은 고객의 다양한 취향과 요구에 따라 최적화된 여행상품을
            제공하며, 높은 만족도와 편안한 여행 경험을 제공하고 있습니다. 또한
            고객의 편의를 위해 전문적인 여행 상담을 제공하며, 여행 계획 수립부터
            여행 종료까지 전 과정을 신속하고 안전하게 처리해드립니다.
            <br />
            우리트래블은 지속적인 고객 만족도 향상을 위해 최신 여행 트렌드와
            여행정보를 항시 파악하며, 고객의 다양한 요구에 대응하는 차별화된
            상품을 제공하고 있습니다. 고객이 언제 어디서나 여행을 즐길 수 있도록
            다양한 여행 상품을 제공하며, 안전하고 즐거운 여행을 할 수 있도록
            최선을 다하고 있습니다.
            <br />
            우리는 여행중(URI TRAVEL)과 함께하면 특별한 추억과 경험을 쌓으며,
            평생 잊지 못할 여행을 즐길 수 있습니다.
          </div>
          <div className="right center mb-3">
            <h3 className="main-theme-text-color">
              Why? 왜 우리트레블 이어야 하나?
            </h3>
            <ol>
              <li>
                전문적인 가이드 서비스: 우리트래블의 가이드들은 모두 여행
                분야에서 전문적인 교육과 경험을 갖춘 전문적인 가이드들입니다.
                고객들은 이들 가이드의 도움으로 그동안 놓쳤던 여행의 매력을
                새롭게 발견할 수 있습니다.
              </li>
              <li>
                맞춤형 여행상품 제공: 우리트래블은 고객의 다양한 요구와 취향에
                맞춰 최적화된 여행상품을 제공합니다. 고객은 자신에게 가장 적합한
                여행상품을 선택할 수 있으며, 더욱 편안하고 만족스러운 여행을
                즐길 수 있습니다.
              </li>
              <li>
                안전하고 편안한 여행: 우리트래블은 여행 도중 고객의 안전을
                최우선으로 생각합니다. 전문적인 안전 관리 시스템을 운영하며,
                여행 중 발생할 수 있는 다양한 문제에 대비하여 대응할 수 있는
                체계를 구축하고 있습니다.
              </li>
              <li>
                다양한 여행 활동 제공: 우리트래블은 고객들이 다양한 여행 활동을
                즐길 수 있도록 다양한 옵션을 제공합니다. 문화유산 탐방부터
                액티비티, 쇼핑까지 고객들이 즐길 수 있는 다양한 활동을 제공하여
                새로운 경험과 추억을 만들어 드립니다.
              </li>
              <li>
                최상의 여행 경험 제공: 우리트래블은 고객의 만족도를 최우선으로
                생각합니다. 고객이 편안하고 즐거운 여행을 즐길 수 있도록 최선을
                다하며, 평생 잊지 못할 추억과 경험을 만들어 드립니다.
              </li>
            </ol>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}
