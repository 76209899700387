import { Field, Form } from '@availity/form'
import axios from 'axios'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Col } from 'reactstrap'
import * as yup from 'yup'
import { default_alert } from '../../common/system/alert/alert'
import PasswordStrengthChecker from '../../common/system/password_strength_checker/password_strength_checker'

export function ForgotPassword(props: {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticated: boolean
  currentPath: string
}) {
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('')
  const [mailSent, setMailSent] = useState<Boolean>(false)
  const [emailAddress, setEmailAddress] = useState<string>('')
  const handleSubmit = async (values: { emailAddress: string }) => {
    const { data } = await axios.post(
      process.env.SERVER_HOST + '/api/user/send_reset_password_link',
      values
    )

    if (data.status === parseInt('500')) {
      setErrorMessage(
        <Col md="12 mt-3">
          <Alert color="danger">{data.response}</Alert>
        </Col>
      )
      setMailSent(false)
    } else {
      setMailSent(true)
      setEmailAddress(values.emailAddress)
    }
  }

  return (
    <div className="app-container">
      <div className="auth-container">
        {errorMessage}
        <Col md="12" className="mt-5">
          {mailSent ? (
            <React.Fragment>
              <h5>받은 편지함을 확인해 주세요!</h5>
              <p>
                {emailAddress} 주소로 비밀번호를 재설정할 수 있는 링크를
                보내드렸습니다. 이메일을 수신하는 데 몇 분이 걸릴 수 있습니다.
                10분 후에도 받지 못한 경우에는 정크 또는 스팸 메일함을 확인해
                주세요.
              </p>
              <p>
                <button
                  type="button"
                  className="btn uri-travel-btn"
                  onClick={() => handleSubmit({ emailAddress: emailAddress })}
                >
                  링크 재전송
                </button>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h5>비밀번호 재설정</h5>
              <h6>
                가입하신 이메일을 입력해 주세요. 비밀번호 재설정을 위한 링크를
                보내드립니다.
              </h6>

              <Form
                initialValues={{
                  emailAddress: '',
                }}
                validationSchema={yup.object().shape({
                  emailAddress: yup
                    .string()
                    .isRequired(true, '이메일 주소를 입력하세요.'),
                })}
                onSubmit={handleSubmit}
              >
                <div className="form-group mt-3">
                  <label htmlFor="emailAddress">이메일</label>
                  <Field
                    name="emailAddress"
                    type="email"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    validate={(value) => {
                      const email_pattern =
                        /^[A-Z0-9\._%-]+@[A-Z0-9\.-]+\.[A-Z]{2,4}(?:[;][A-Z0-9\._%-]+@[A-Z0-9\.-]+\.[A-Z]{2,4})*$/i

                      if (!email_pattern.test(value)) {
                        return '이메일이 유효하지 않습니다.'
                      }
                    }}
                  />
                </div>

                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="uri-travel-btn btn">
                    재설정 링크 보내기
                  </button>
                </div>
              </Form>
            </React.Fragment>
          )}
        </Col>
      </div>
    </div>
  )
}

export function ResetPassword(props: {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticated: boolean
  currentPath: string
}) {
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('')
  const navigate = useNavigate()
  const { currentPath, isAuthenticated, setIsAuthenticated } = props
  const [emailAddress, setEmailAddress] = useState<string>('')
  useEffect(() => {
    if (isAuthenticated) {
      navigate(currentPath === null || currentPath === '' ? '/' : currentPath)
    }
  }, [isAuthenticated])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')

    const payload = { token }

    axios
      .post(
        process.env.SERVER_HOST + '/api/user/validate_reset_password_link',
        payload
      )
      .then((response) => {
        setEmailAddress(response.data.emailAddress)
      })
      .catch((err) => {
        setErrorMessage(err)
      })
  }, [])

  const handleSubmit = async (values: any) => {
    const payload = {
      emailAddress: emailAddress,
      password: values.password,
    }

    axios
      .post(process.env.SERVER_HOST + '/api/user/reset_password', payload)
      .then((response) => {
        default_alert(
          response.data.message + ' 잠시후 로그인 화면으로 이동합니다.'
        )
        setTimeout(() => {
          navigate('/sign_in')
        }, 100)
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  const [inputValues, setInputValues] = useState({
    password: '',
    confirmedPassword: '',
  })

  function handelInputChange(
    ev: ChangeEvent<HTMLInputElement>,
    name: string
  ): void {
    const new_input_values = JSON.parse(JSON.stringify(inputValues))

    new_input_values[name] = ev.target.value

    setInputValues(new_input_values)
  }

  return (
    <div className="app-container">
      <div className="auth-container">
        {errorMessage}
        <Col md="12" className="mt-5">
          <h5>비밀번호 재설정</h5>
          <h6>사용하실 비밀번호를 입력해주세요.</h6>

          <Form
            initialValues={{
              password: '',
              confirmedPassword: '',
            }}
            validationSchema={yup.object().shape({
              password: yup.string().isRequired(true, '비밀번호를 입력하세요.'),
              confirmedPassword: yup
                .string()
                .isRequired(true, '비밀번호를 다시 한번 입력하세요.'),
            })}
            onSubmit={handleSubmit}
          >
            <div className="form-group mt-3">
              <label htmlFor="password">비밀번호</label>
              <Field
                name="password"
                autoComplete="off"
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={(ev) => handelInputChange(ev, 'password')}
                validate={(value) => {
                  const special_chars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

                  if (
                    !special_chars.test(value) ||
                    !value.match(/[A-Z]/) ||
                    value.length < 6 ||
                    !value.match(/[a-z]/) ||
                    !value.match(/\d/)
                  ) {
                    return '비밀번호가 단순합니다. 6자 이상의 영문 대/소문자, 숫자 및 특수문자 조합으로 입력하셔야 합니다.'
                  }
                }}
              />
              <PasswordStrengthChecker password={inputValues.password} />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="confirm">비밀번호 확인</label>
              <Field
                name="confirmedPassword"
                autoComplete="off"
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={(ev) => handelInputChange(ev, 'confirmedPassword')}
                validate={(value) => {
                  if (value !== inputValues.password) {
                    return '비밀번호가 일치하지 않습니다.'
                  }
                }}
              />
            </div>

            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="uri-travel-btn btn">
                비밀번호 변경하기
              </button>
            </div>
          </Form>
        </Col>
      </div>
    </div>
  )
}
