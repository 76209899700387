import React from 'react'
import { Col, Row } from 'reactstrap'
import { withUseParamsHOC } from '../../../common/system/route/route'
import {
  CustomTourInquiryForm,
  displayTourPackages,
  profileBasciInfoCard,
} from './guide_profile_components'

import './../../../sass/guide_profile/guide_profile.scss'

import axios from 'axios'
import { default_alert } from '../../../common/system/alert/alert'

class GuideProfileClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      guide_data: {},
      tour_data: {},

      custom_tour_modal_settings: {
        is_open: false,
      },
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleClickTourCard = this.handleClickTourCard.bind(this)
    this.handleClickCustomTourRequest =
      this.handleClickCustomTourRequest.bind(this)
    this.processCustomTourRequest = this.processCustomTourRequest.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    const vm = this
    vm.fetchData()
  }

  fetchData() {
    const vm = this

    const url =
      process.env.SERVER_HOST + '/guide/profile/' + vm.props.router.params.id

    axios.get(url).then((response) => {
      const response_data = response.data

      const vm = this

      let guide_data = {}
      let tour_data = {}

      if (
        typeof response_data.guide_data !== 'undefined' &&
        Object.keys(response_data.guide_data).length > 0
      ) {
        guide_data = response_data.guide_data
      }

      if (
        typeof response_data.tour_data !== 'undefined' &&
        Object.keys(response_data.tour_data).length > 0
      ) {
        tour_data = response_data.tour_data
      }

      vm.setState({
        guide_data,
        tour_data,
      })
    })
  }

  handleClickTourCard(
    ev: React.MouseEvent<HTMLButtonElement>,
    inventory_id: number
  ) {
    const url = '/tour_package/' + inventory_id

    window.open(url)
  }

  handleClickCustomTourRequest(ev: React.MouseEvent<HTMLButtonElement>) {
    const vm = this
    vm.setState({
      custom_tour_modal_settings: {
        is_open: true,
      },
    })
  }

  processCustomTourRequest() {}

  handleCloseModal(ev: React.MouseEvent<HTMLElement>) {
    const vm = this

    vm.setState({
      custom_tour_modal_settings: {
        is_open: false,
      },
    })
  }

  handleFormSubmit(values: { name: string; email: string; content: string }) {
    const vm = this

    const url = process.env.SERVER_HOST + '/guide/custom_tour_inquiry'

    const payload = {
      customer_info: values,
      guide_id: vm.props.router.params.id,
    }

    axios
      .post(url, payload)
      .then((response) => {
        const response_data = response.data

        if (response_data.result) {
          vm.setState(
            {
              custom_tour_modal_settings: {
                is_open: false,
              },
            },
            () => {
              default_alert(
                '문의 하신 내용을 빠른 시일내에 검토하여 답변 드리겠습니다.'
              )
            }
          )
        } else {
          default_alert(
            '고객님께서 보내신 문의 메일이 전송되지 못했습니다. 이용중 불편을 드려서 죄송합니다.'
          )
        }
      })
      .catch((err) => {
        default_alert(
          '고객님께서 보내신 문의 메일이 전송되지 못했습니다. 이용중 불편을 드려서 죄송합니다.'
        )
      })
  }

  render(): React.ReactNode {
    const vm = this

    const { guide_data, tour_data, custom_tour_modal_settings } = vm.state

    const profile = guide_data.profile ?? {}

    const profile_basic_card =
      Object.keys(profile).length > 0 ? profileBasciInfoCard(profile, vm) : ''

    const tour_list =
      Object.keys(tour_data).length > 0
        ? displayTourPackages(tour_data, vm)
        : ''

    let custom_tour_inquiry: string | JSX.Element = ''

    if (custom_tour_modal_settings.is_open) {
      custom_tour_inquiry = (
        <CustomTourInquiryForm
          is_open={custom_tour_modal_settings.is_open}
          handleFormSubmit={vm.handleFormSubmit}
          handleClose={vm.handleCloseModal}
        />
      )
    }

    return (
      <React.Fragment>
        <Row className="app-container mt-5">
          <Col xs="12">{profile_basic_card}</Col>
          <Col xs="12" className="mt-5">
            <h5 className="fw-bold">{profile.displayName}님의 투어</h5>
          </Col>
          <Col xs="12">{tour_list}</Col>
        </Row>
        {custom_tour_inquiry}
      </React.Fragment>
    )
  }
}

const GuideProfile = withUseParamsHOC(GuideProfileClass)
export default GuideProfile
