import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { MapStateToProps, ReduxState, InputFieldProp } from './types'
import { Form, Field } from '@availity/form'
import * as yup from 'yup'
import {
  ImageUpload,
  InputField,
  sanitizeRowIndexFromKey,
} from './tour_management_components'
import { DropdownOptionsType } from '../../common/type/dropdown'
import axios from 'axios'
import { default_alert } from '../../common/system/alert/alert'

type Schdeule = {
  image_key: string
  name: string
  description: string
}

type ClassState = {
  input_values: {
    tour_name: string
    description: string
    schedules: Array<Schdeule> | []
    departure: string
    meeting_time: string
    primary_note: string
    secondary_note: string
    cancellation_policy: string
    guide: string
    cost: string
    personnel: string
    tour_hours: string
  }
  basic_form_settings:
    | {
        tour_name: InputFieldProp
        description: InputFieldProp
      }
    | {}

  detail_form_settings:
    | {
        departure: InputFieldProp
        meeting_time: InputFieldProp
        primary_note: InputFieldProp
        secondary_note: InputFieldProp
        cancellation_policy: InputFieldProp
      }
    | {}

  guide_info_form_settings:
    | {
        guide: InputFieldProp
        cost: InputFieldProp
        personnel: InputFieldProp
        tour_hours: InputFieldProp
      }
    | {}

  images: {
    [key: string]: File | null
  }
}

type ClassProps = {
  is_open: boolean
  handleModalClose: () => void
}

type Props = MapStateToProps & ClassProps & {}

const mapStateToProps = (state: ReduxState): MapStateToProps => {
  return {
    filter_options: state.filter_settings,
  }
}

const mapDispatchToProps = {}

/** TODO:: Need to clean up & restructre code */

class TourManagementAddModalClass extends React.Component<Props, ClassState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      input_values: {
        tour_name: '',
        description: '',
        schedules: [{ image_key: '', name: '', description: '' }],
        departure: '',
        meeting_time: '',
        primary_note: '',
        secondary_note: '',
        cancellation_policy: '',
        guide: '',
        cost: '',
        personnel: '',
        tour_hours: '',
      },

      images: {},

      basic_form_settings: {},

      detail_form_settings: {},

      guide_info_form_settings: {},
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleAddNewSchedule = this.handleAddNewSchedule.bind(this)
    this.handleDeleteImage = this.handleDeleteImage.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    const vm = this
    vm.fetchData()
  }

  fetchData() {
    const url =
      process.env.SERVER_HOST +
      '/admin/tour_management/get_tour_management_settings'

    axios.get(url).then((response) => {
      const response_data = response.data

      const vm = this

      let basic_form_settings = {}
      let detail_form_settings = {}
      let guide_info_form_settings = {}

      const form_settings = response_data.form_settings ?? {}

      if (
        typeof form_settings.basic_form_settings !== 'undefined' &&
        Object.keys(form_settings.basic_form_settings).length > 0
      ) {
        basic_form_settings = form_settings.basic_form_settings
      }

      if (
        typeof form_settings.detail_form_settings !== 'undefined' &&
        Object.keys(form_settings.detail_form_settings).length > 0
      ) {
        detail_form_settings = form_settings.detail_form_settings
      }

      if (
        typeof form_settings.guide_info_form_settings !== 'undefined' &&
        Object.keys(form_settings.guide_info_form_settings).length > 0
      ) {
        guide_info_form_settings = form_settings.guide_info_form_settings
      }

      vm.setState({
        basic_form_settings,
        detail_form_settings,
        guide_info_form_settings,
      })
    })
  }

  handleFileUpload(
    e: DragEvent | React.ChangeEvent<HTMLInputElement>,
    type = 'choose_file',
    key: string
  ) {
    const vm = this

    let file = null

    if (type === 'drag_drop') {
      const ev = e as DragEvent

      file = ev.dataTransfer !== null ? ev.dataTransfer.files[0] : null
    } else {
      const ev = e as React.ChangeEvent<HTMLInputElement>

      file = ev.target.files !== null ? ev.target.files[0] : null
    }

    const input_values = { ...vm.state.input_values }

    if (file) {
      if (key.match(/^schedule_thumnail_\d+$/)) {
        const row_id = sanitizeRowIndexFromKey(key, 'schedule_thumnail')

        const schedule = input_values.schedules

        schedule[row_id]['image_key'] = file.name
      }

      const images = { ...vm.state.images }
      images[key] = file

      vm.setState({ images, input_values })
    }
  }

  handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    form_id: string,
    field_id: string,
    row_id = 0
  ) {
    const vm = this
    const target = e.target
    const value = target.value

    const input_values = { ...vm.state.input_values }

    if (form_id === 'schedules_info_form') {
      input_values['schedules'][row_id][
        field_id === 'name' ? 'name' : 'description'
      ] = value
    } else {
      type AllowedObjKeys =
        | 'tour_name'
        | 'description'
        | 'departure'
        | 'meeting_time'
        | 'primary_note'
        | 'secondary_note'
        | 'cancellation_policy'

      input_values[field_id as AllowedObjKeys] = value
    }

    vm.setState({
      input_values: input_values,
    })
  }

  handleFormSubmit(values: any) {
    const vm = this

    const input_values = vm.state.input_values
    const images = vm.state.images

    const url =
      process.env.SERVER_HOST + '/admin/tour_management/add_update_tour_package'

    const formData: any = new FormData()

    Object.keys(images).forEach((key) => {
      formData.append('files[]', images[key])
    })

    formData.append('input_values', JSON.stringify(input_values))

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    axios
      .post(url, formData, config)
      .then((response) => {
        const response_data = response.data

        if (!response_data.result) {
          default_alert(response_data.message)
        }
      })
      .catch((err) => {
        default_alert(err)
      })
      .finally(() => {
        //vm.props.handleModalClose();
      })
  }

  handleAddNewSchedule() {
    const vm = this

    const input_values = { ...vm.state.input_values }

    const schedules: Array<Schdeule> = input_values.schedules

    const new_schedule_info = { image_key: '', name: '', description: '' }

    schedules.push(new_schedule_info)

    vm.setState({ input_values })
  }

  handleDeleteImage(key: string) {
    const vm = this

    const input_values = { ...vm.state.input_values }
    const images = { ...vm.state.images }

    if (key.match(/^schedule_thumnail_\d+$/)) {
      const row_id = sanitizeRowIndexFromKey(key, 'schedule_thumnail')

      const schedule = input_values.schedules

      schedule[row_id]['image_key'] = ''
    }

    images[key] = null

    vm.setState({ input_values, images })
  }

  handleSelectChange(new_value: DropdownOptionsType, field_id: string) {
    const vm = this
    const input_values = { ...vm.state.input_values }

    type AllowedObjKeys = 'guide'

    const value = new_value.value ?? ''

    input_values[field_id as AllowedObjKeys] = value.toString()

    vm.setState({
      input_values,
    })
  }

  render(): React.ReactNode {
    const vm = this

    const {
      basic_form_settings,
      detail_form_settings,
      guide_info_form_settings,
      input_values,
      images,
    } = vm.state

    let primary_tour_image: string | JSX.Element = ''
    if (
      typeof images.main_image !== 'undefined' &&
      images.main_image !== null
    ) {
      const preview = URL.createObjectURL(images.main_image as File)
      primary_tour_image = (
        <div className="image-wrapper">
          <img
            src={preview}
            style={{ width: '100%', height: '425px' }}
            alt="main_image"
          />
          <button
            type="button"
            className="remove-image"
            style={{ display: 'inline' }}
            onClick={() => vm.handleDeleteImage('main_image')}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg"></i>
          </button>
        </div>
      )
    } else {
      primary_tour_image = (
        <ImageUpload
          handleImageUpload={vm.handleFileUpload}
          multipleUpload={false}
          style={{ height: '425px' }}
          name={'main_image[]'}
          id={'main_image'}
        />
      )
    }

    const sub_image_fields = []
    for (let i = 1; i <= 4; i++) {
      let sub_image: string | JSX.Element = ''
      const image_id = 'sub_image_' + i

      if (
        typeof images[image_id] !== 'undefined' &&
        images[image_id] !== null
      ) {
        const preview = URL.createObjectURL(images[image_id] as File)
        sub_image = (
          <div className="image-wrapper">
            <img
              src={preview}
              style={{ width: '100%', height: '200px' }}
              alt="sub_image"
            />
            <button
              type="button"
              className="remove-image"
              style={{ display: 'inline' }}
              onClick={() => vm.handleDeleteImage(image_id)}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg"></i>
            </button>
          </div>
        )
      } else {
        sub_image = (
          <ImageUpload
            handleImageUpload={vm.handleFileUpload}
            multipleUpload={false}
            style={{ height: '200px' }}
            name={image_id + '[]'}
            id={image_id}
          />
        )
      }

      sub_image_fields.push(
        <div
          className="col-6 sub-image-form mb-4"
          key={'sub_image_container_' + i}
        >
          {sub_image}
        </div>
      )
    }

    let basic_input_fields: string | Array<JSX.Element> = ''

    if (Object.keys(basic_form_settings).length > 0) {
      basic_input_fields = Object.keys(basic_form_settings).map((key) => {
        const config =
          basic_form_settings[key as keyof typeof basic_form_settings]

        return (
          <React.Fragment key={key}>
            <InputField
              config={config}
              value={input_values[key as keyof typeof basic_form_settings]}
              form_id={'basic_info_form'}
              handleChange={vm.handleInputChange}
              handleSelectChange={vm.handleSelectChange}
            />
          </React.Fragment>
        )
      })
    }

    let details_input_fields: string | Array<JSX.Element> = ''
    if (Object.keys(detail_form_settings).length > 0) {
      details_input_fields = Object.keys(detail_form_settings).map((key) => {
        const config =
          detail_form_settings[key as keyof typeof detail_form_settings]

        return (
          <React.Fragment key={key}>
            <InputField
              config={config}
              value={input_values[key as keyof typeof detail_form_settings]}
              form_id={'detail_info_form'}
              handleChange={vm.handleInputChange}
              handleSelectChange={vm.handleSelectChange}
            />
            <hr />
          </React.Fragment>
        )
      })
    }

    const schedules_field = Object.keys(input_values.schedules).map(
      (key, index) => {
        let thumnail: string | JSX.Element = ''

        const image_id = 'schedule_thumnail_' + index
        if (
          typeof images[image_id] !== 'undefined' &&
          images[image_id] !== null
        ) {
          const preview = URL.createObjectURL(images[image_id] as File)
          thumnail = (
            <div className="image-wrapper">
              <img src={preview} style={{ width: '100%' }} alt="thumnail" />
              <button
                className="remove-image"
                style={{ display: 'inline' }}
                onClick={() => vm.handleDeleteImage(image_id)}
              >
                <i className="zmdi zmdi-close zmdi-hc-lg"></i>
              </button>
            </div>
          )
        } else {
          thumnail = (
            <ImageUpload
              handleImageUpload={vm.handleFileUpload}
              multipleUpload={false}
              style={{ height: '200px' }}
              name={'schedule_thumnail' + index + '[]'}
              id={'schedule_thumnail_' + index}
            />
          )
        }

        return (
          <React.Fragment key={key}>
            <div className="col-3 mb-2">{thumnail}</div>
            <div className="col-8 mb-2">
              <Field
                name={'schedule_name' + index}
                id={'schedule_name' + index}
                placeholder={'일정지 제목'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  vm.handleInputChange(
                    e,
                    'schedules_info_form',
                    'name',
                    parseInt(key)
                  )
                }
                required
              />
              <Field
                name={'schedule_description' + index}
                id={'schedule_description' + index}
                type="textarea"
                placeholder={'일정지 설명'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  vm.handleInputChange(
                    e,
                    'schedules_info_form',
                    'description',
                    parseInt(key)
                  )
                }
                required
              />
            </div>
          </React.Fragment>
        )
      }
    )

    let guide_info: string | Array<JSX.Element> = ''

    if (Object.keys(guide_info_form_settings).length > 0) {
      guide_info = Object.keys(guide_info_form_settings).map((key) => {
        const config =
          guide_info_form_settings[key as keyof typeof guide_info_form_settings]
        return (
          <React.Fragment key={key}>
            <InputField
              config={config}
              value={input_values[key as keyof typeof guide_info_form_settings]}
              form_id={'guide_info_form_settings'}
              handleChange={vm.handleInputChange}
              handleSelectChange={vm.handleSelectChange}
            />
            <hr />
          </React.Fragment>
        )
      })
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={vm.props.is_open}
          className="w-75 mw-100"
          contentClassName="modal_overflow"
          centered={true}
        >
          <ModalHeader>
            <div className={'d-flex flex-column'}>
              <h5 className="modal-title font-weight-bold mb-1">투어 업로더</h5>
            </div>
          </ModalHeader>
          <Form
            initialValues={vm.state.input_values}
            onSubmit={vm.handleFormSubmit}
            validationSchema={yup.object({
              tour_name: yup.string().required(),
              description: yup.string().required(),
              departure: yup.string().required(),
              meeting_time: yup.string().required(),
              primary_note: yup.string().required(),
              secondary_note: yup.string().required(),
              cancellation_policy: yup.string().required(),
            })}
          >
            <ModalBody className="border-top">
              <Row className="tour-images-forms-group">
                <div className="col-6 row">
                  <div className="col-12">{primary_tour_image}</div>
                </div>
                <div className="col-6 row">{sub_image_fields}</div>
              </Row>
              <hr />

              <Row>
                <div className="col-8">
                  <div className="col-12">{basic_input_fields}</div>
                  <hr />
                  <div className="col-12 row">{schedules_field}</div>
                  <div className="col-12 text-right mt-1">
                    <button
                      type="button"
                      onClick={vm.handleAddNewSchedule}
                      className="btn btn-minus mt-0 add_row"
                    >
                      <i className="zmdi zmdi-plus-circle-o zmdi-hc-lg"> </i>
                      &nbsp; 일정 추가
                    </button>
                  </div>
                  <hr />
                  <div className="col-12">{details_input_fields}</div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">투어 가이드</h5>
                      <p className="card-text">
                        <div className="col-12">{guide_info}</div>
                      </p>
                    </div>
                  </div>
                </div>
              </Row>
            </ModalBody>

            <ModalFooter className="border-top">
              <Button
                color="secondary"
                type="button"
                onClick={() => vm.props.handleModalClose()}
              >
                &nbsp;&nbsp;Close&nbsp;&nbsp;
              </Button>
              <Button color="success" type="submit">
                &nbsp;Add Tour&nbsp;
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const TourManagementAddModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TourManagementAddModalClass)
export default TourManagementAddModal
