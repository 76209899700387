import { Card, CardBody, Col, Row } from 'reactstrap'
import { Form, Field, CheckboxGroup, Checkbox } from '@availity/form'
import '@availity/date/styles.scss'
import * as yup from 'yup'
import './../../sass/guide_application/guide_application.scss'

export const GuideApplicationForm = (props: any) => {
  return (
    <Card className="guide-application-form">
      <Col xs="12"></Col>
      <CardBody>
        <Form
          initialValues={{
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            description: '',
            guilde_terms_of_service: [],
          }}
          onSubmit={props.handleFormSubmit}
          validationSchema={yup.object().shape({
            firstName: yup.string().isRequired(true),
            lastName: yup.string().isRequired(true),
            emailAddress: yup.string().isRequired(true),
            phoneNumber: yup.string().isRequired(true),
            description: yup.string().isRequired(true),
            guilde_terms_of_service: yup.array().isRequired(true),
          })}
        >
          <Row>
            <Col md="6">
              <Field
                label="First Name"
                name="firstName"
                placeholder=""
                required
              />
            </Col>
            <Col md="6">
              <Field
                label="Last Name"
                name="lastName"
                placeholder=""
                required
              />
            </Col>
            <Col md="12">
              <Field
                label="Email"
                name="emailAddress"
                placeholder=""
                required
              />
            </Col>
            <Col md="12">
              <Field label="Phone" name="phoneNumber" placeholder="" required />
            </Col>
            <Col md="12">
              <Field
                label="About me"
                name="description"
                placeholder=""
                type="textarea"
                required
                style={{ height: '200px' }}
              />
            </Col>
            <Col md="12">
              <CheckboxGroup name="guilde_terms_of_service" required>
                <Checkbox groupName="guilde_terms_of_service" value="Yes" />
                본인은 가이드{' '}
                <a href="/guide_terms_of_service" target="_blank">
                  이용약관
                </a>
                을 읽었고 이에 동의 합니다.
              </CheckboxGroup>
            </Col>
            <Col md="12" className="text-center">
              <button type="submit" className="uri-travel-btn btn">
                가이드 신청하기
              </button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
