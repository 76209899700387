import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Form, Field, CheckboxGroup, Checkbox } from '@availity/form'
import { DateField } from '@availity/date'
import '@availity/date/styles.scss'
import { avDate } from '@availity/yup'
import * as yup from 'yup'
import { Rating, ScheduleInfo, TravelInquiryInputs } from './types'
import { SelectField } from '@availity/select'
import Select, { OptionsOrGroups } from 'react-select'
import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

export const tourHeaderImages = (tour_images: Array<string>) => {
  const smallItemStyles: React.CSSProperties = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
    borderRadius: '8px',
  }

  const mainItemStyles: React.CSSProperties = {
    cursor: 'pointer',
    width: '100%',
    gridRow: 'span 2',
    borderRadius: '8px',
  }

  const tour_images_grid = Object.keys(tour_images).map((key, index) => {
    const image_url =
      tour_images[index].length > 0
        ? tour_images[index]
        : 'tour_image_1920x1080.png'

    return (
      <Item
        key={'tour_image_' + index}
        original={require('./../../assets/images/' + image_url)}
        thumbnail={require('./../../assets/images/' + image_url)}
        width="1270"
        height="720"
        alt="Photo of seashore by Folkert Gorter"
      >
        {({ ref, open }) => (
          <img
            style={index === 0 ? mainItemStyles : smallItemStyles}
            src={require('./../../assets/images/' + image_url)}
            ref={ref as React.MutableRefObject<HTMLImageElement>}
            onClick={open}
            alt=""
          />
        )}
      </Item>
    )
  })

  return (
    <Gallery>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1.035fr .5fr .5fr',
          position: 'relative',
          width: '100%',
          gridGap: 10,
        }}
      >
        {tour_images_grid}
      </div>
    </Gallery>
  )
}

export const tourBasicInfo = (
  title: string,
  description: string,
  rating: Rating,
  price: number | Array<{ price: number; maxCapacity: number }>,
  currency: string,
  duration: number,
  capacity: number,
  exchange_rate: {
    CAD: number
    EUR: number
    USD: number
    WON: number
  }
) => {
  const rate_tmp = (rating.stars / rating.counts).toFixed(2)
  let tour_basic_info: string | JSX.Element = ''
  const tour_time = duration + '시간'
  if (Array.isArray(price)) {
    const tour_price = []
    const capacity = []

    for (const i in price) {
      tour_price.push(price[i].price)
      capacity.push(price[i].maxCapacity)
    }

    const high_price = Math.max(...tour_price)
    const row_price = Math.min(...tour_price)

    const max_capacity = Math.max(...capacity)

    tour_basic_info = (
      <React.Fragment>
        &#8361;
        {(row_price * exchange_rate.WON).toLocaleString('en') +
          ' - ' +
          (high_price * exchange_rate.WON).toLocaleString('en') +
          ' WON'}{' '}
        |{' '}
        {'$' +
          row_price.toLocaleString('en') +
          ' - ' +
          high_price.toLocaleString('en') +
          ' ' +
          currency}{' '}
        | {'최대 ' + max_capacity + '인'} | {tour_time}
      </React.Fragment>
    )
  } else {
    const cost = '$' + price.toLocaleString('en') + ' ' + currency
    const people = '최대 ' + capacity + '인'

    tour_basic_info = (
      <React.Fragment>
        &#8361;{(price * exchange_rate.WON).toLocaleString('en') + ' WON'} |{' '}
        {cost} | {people} | {tour_time}
      </React.Fragment>
    )
  }

  let rate: JSX.Element | string = ''
  if (!isNaN(parseInt(rate_tmp))) {
    rate = (
      <React.Fragment>
        <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <h5 className="fw-bold">{title}</h5>
      <div className="is-tablet-mobile">
        <Col>
          <h6 className="fw-bold">{tour_basic_info}</h6>
        </Col>
      </div>
      <h6>{rate}</h6>
      <p className="tour-description">{description}</p>
    </React.Fragment>
  )
}

export const tourDetailedInfo = (
  meetingLocation: string,
  tourStarttime: string,
  included: string,
  notIncluded: string,
  extraInformation: string,
  cancellationPolicy: string
) => {
  return (
    <React.Fragment>
      <Row className="tour-description">
        <Col md="12">
          <h5 className="fw-bold">미팅 장소</h5>
          <p>{meetingLocation}</p>
          <hr />
        </Col>
        <Col md="12">
          <h5 className="fw-bold">미팅 시간</h5>
          <p>{tourStarttime}</p>
          <hr />
        </Col>
        <Col md="12">
          <h5 className="fw-bold">포함 사항</h5>
          <p>{included}</p>
          <hr />
        </Col>
        <Col md="12">
          <h5 className="fw-bold">불포함 사항</h5>
          <p>{notIncluded}</p>
          <hr />
        </Col>
        <Col md="12">
          <h5 className="fw-bold">참고 사항</h5>
          <p>{extraInformation}</p>
          <hr />
        </Col>
        <Col md="12">
          <h5 className="fw-bold">취소 및 환불 규정</h5>
          <p>{cancellationPolicy}</p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const tourSchedulesInfo = (schedules_info: Array<ScheduleInfo>) => {
  const schedules = Object.keys(schedules_info).map((key, index) => {
    return (
      <Row key={'tour_schedule_' + key} className="tour-description">
        {generateTourScheduleInfo(schedules_info[index])}
      </Row>
    )
  })

  return (
    <React.Fragment>
      <Col md="12" className="mb-3">
        <h5 className="fw-bold">투어 일정표</h5>
      </Col>

      {schedules}
    </React.Fragment>
  )
}

const generateTourScheduleInfo = (schedule_info: ScheduleInfo) => {
  const image_url =
    schedule_info.photoUrl.length > 0
      ? schedule_info.photoUrl
      : 'tour_image_1920x1080.png'

  return (
    <React.Fragment>
      <Col md="3" className="mb-2">
        <figure className="figure">
          <img
            src={require('./../../assets/images/' + image_url)}
            className="figure-img img-fluid rounded"
            alt=""
          />
        </figure>
      </Col>
      <Col md="9" className="mb-2">
        <h6 className="fw-bold">
          <i className="zmdi zmdi-pin zmdi-hc-lg"></i>&nbsp;
          {schedule_info.title}
        </h6>
        <p>{schedule_info.description}</p>
      </Col>
    </React.Fragment>
  )
}

export const TourInquiryForm = (props: {
  guide_id: string
  guide: {
    displayName: string
    profilePicture: BinaryData
    rating: {
      star: number
      count: number
    }
  }
  tour: {
    productType: string
    price: number | Array<{ price: number; maxCapacity: number }>
    currency: string
    duration: number
    capacity: number
    customized: boolean
  }
  handleFormSubmit: (values: TravelInquiryInputs, actions: any) => void

  exchange_rate: {
    CAD: number
    EUR: number
    USD: number
    WON: number
  }
}) => {
  const { guide, tour } = props

  const keepLogin = localStorage.getItem('keepLogin') ?? 'No'

  let is_user_logged_in = false
  let user_info = null
  if (keepLogin === 'Yes') {
    is_user_logged_in = localStorage.getItem('token') ? true : false
    user_info = JSON.parse(localStorage.getItem('user') ?? 'null')
  } else if (keepLogin === 'No') {
    is_user_logged_in = sessionStorage.getItem('token') ? true : false
    user_info = JSON.parse(sessionStorage.getItem('user') ?? 'null')
  }

  const name = guide.displayName
  const rate_tmp = (guide.rating.star / guide.rating.count).toFixed(2)

  const currency = tour.currency

  const tour_time = tour.duration + '시간'
  const tour_customized = tour.customized

  const price_info = tour.price

  let options: Array<{
    label: string
    value: { price: number; maxCapacity: number }
  }> = []

  let maxCapacity = 0
  console.log('price: ', price_info)
  if (Array.isArray(price_info)) {
    for (const i in price_info) {
      const price = price_info[i].price
      const capacity = price_info[i].maxCapacity

      const cost = '$' + price.toLocaleString('en') + ' ' + currency
      const people = '최대 ' + capacity + '인'
      const price_krw =
        (price * props.exchange_rate.WON).toLocaleString('en') + ' WON'

      options.push({
        label: price_krw + ' ' + cost + ' ' + people,
        value: price_info[i],
      })

      maxCapacity = maxCapacity < capacity ? capacity : maxCapacity
    }
  } else {
    const cost = '$' + price_info.toLocaleString('en') + ' ' + currency
    const people = '최대 ' + tour.capacity + '인'
    const price_krw =
      (price_info * props.exchange_rate.WON).toLocaleString('en') + ' WON'

    options = [
      {
        label: price_krw + ' ' + cost + ' ' + people,
        value: { price: price_info, maxCapacity: tour.capacity },
      },
    ]

    maxCapacity = tour.capacity
  }

  let rate: JSX.Element | string = ''
  if (!isNaN(parseInt(rate_tmp))) {
    rate = (
      <React.Fragment>
        <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
      </React.Fragment>
    )
  }

  let form: string | JSX.Element = ''

  if (!tour_customized) {
    if (is_user_logged_in) {
      form = (
        <Col md="12" className="mt-2">
          <Form
            initialValues={{
              tour_options: undefined,
              name: '',
              email: '',
              tour_date: '',
              details: '',
              custom_tour_inquiry: '',
            }}
            onSubmit={props.handleFormSubmit}
            validationSchema={yup.object().shape({
              tour_options: yup.object().isRequired(true),
              name: yup.string().isRequired(true),
              email: yup.string().isRequired(true),
              tour_date: avDate().isRequired(true),
            })}
          >
            <SelectField
              name={'tour_options'}
              aria-label="stand-alone"
              options={options}
              isMulti={false}
              placeholder={'가격 정보'}
              feedback={false}
              required
              labelHidden={true}
            />
            <Field name="name" placeholder="이름" required />
            <Field name="email" placeholder="이메일" required />
            <DateField
              id="tour_date"
              name="tour_date"
              min={new Date().toISOString().slice(0, 10)}
            />
            <Field
              name="details"
              id="details"
              type="textarea"
              placeholder="참고 사항 또는 변경 사항"
            />
            {tour.productType === 'Tour' ? (
              <CheckboxGroup name="custom_tour_inquiry">
                <Checkbox
                  groupName="custom_tour"
                  label="개인 커스텀 투어 제작 문의"
                  value="Yes"
                />
              </CheckboxGroup>
            ) : (
              ''
            )}
            <button type="submit" className="w-100 uri-travel-btn btn">
              문의하기
            </button>
          </Form>
        </Col>
      )
    } else {
      form = (
        <Col md="12" className="mt-2">
          <Select
            name={'tour_options'}
            aria-label="stand-alone"
            options={options}
            isMulti={false}
            placeholder={'가격 정보'}
            defaultValue={options[0]}
          />
          <a
            href="/sign_in"
            type="button"
            className="w-100 uri-travel-btn btn mt-2"
          >
            문의하기
          </a>
        </Col>
      )
    }
  }

  return (
    <Card className="tour-inqury-form">
      <CardBody>
        <Row className="row-center">
          <Col md="5" sm="6">
            <a
              className="tour-guide-link"
              href={'/guide_profile/' + props.guide_id}
            >
              <img
                src={require('./../../assets/images/' +
                  props.guide.profilePicture)}
                className="figure-img img-fluid rounded"
                alt="profile"
              />
            </a>
          </Col>
          <Col md="7" sm="12">
            <CardTitle tag="h5" className="fw-bold">
              투어 가이드
            </CardTitle>
            <CardSubtitle className="mb-2" tag="h6">
              <a
                className="tour-guide-link"
                href={'/guide_profile/' + props.guide_id}
              >
                {name}
              </a>
            </CardSubtitle>
            <p className="fs-11"> {rate} </p>
          </Col>
          <Col md="7" sm="7" xs="7">
            <h6 className="fw-bold">
              <i className="zmdi zmdi-time"></i>
              {tour_time}
            </h6>
          </Col>
          <Col md="5" sm="5" xs="5" className="text-right">
            <h6 className="fw-bold">
              <i className="zmdi zmdi-accounts"></i>
              {'최대 ' + maxCapacity + '인'}
            </h6>
          </Col>
          {form}
        </Row>
      </CardBody>
    </Card>
  )
}

export const ConfirmationModal = (props: {
  is_open: boolean
  handleClose: (ev: React.MouseEvent<HTMLElement>) => void
  customer_info: {
    tour_options: { price: number; maxCapacity: number }
    name: string
    email: string
    tour_date: string
    details: string
  }
  tour_data: {
    title: string
    price: number
    currency: string
    duration: number
    capacity: number
    customized: boolean
  }
  guide_info: {
    displayName: string
    profilePicture: BinaryData
    rating: {
      star: number
      count: number
    }
  }
  exchange_rate: {
    CAD: number
    EUR: number
    USD: number
    WON: number
  }
}) => {
  const tour_options = props.customer_info.tour_options

  const price = tour_options.price

  const cost = '$' + price.toLocaleString('en') + ' ' + props.tour_data.currency

  const people = '최대' + tour_options.maxCapacity + '인'

  const tour_time = props.tour_data.duration + '시간'

  return (
    <React.Fragment>
      <Modal
        isOpen={props.is_open}
        className="w-40 mw-100"
        contentClassName="modal_overflow"
        centered={true}
      >
        <ModalBody className="border-0">
          <Row>
            <Col sm="12" className="text-center mb-3">
              <span className="fw-bold logo-main-title">URI TRAVEL</span>
              <span className="fw-bold logo-sub-title">- 우리는 여행중 -</span>
              <i
                aria-hidden="true"
                className="zmdi zmdi-close zmdi-hc-lg close-modal"
                onClick={(ev) => props.handleClose(ev)}
              ></i>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="3" className="mb-2">
                  <img
                    src={require('./../../assets/images/avatar.png')}
                    className="figure-img img-fluid rounded"
                    alt="profile"
                  />
                </Col>
                <Col sm="9" className="mb-2">
                  <h5 className="fw-bold">투어 가이드</h5>
                  <h6>{props.guide_info.displayName}</h6>
                </Col>
                <Col sm="12">
                  <h6 className="fw-bold mb-1">{props.tour_data.title}</h6>
                </Col>
                <Col sm="12">
                  <h6 className="fw-bold mb-2">
                    &#8361;
                    {(price * props.exchange_rate.WON).toLocaleString('en') +
                      ' WON'}{' '}
                    / {cost} / {people} / {tour_time}
                  </h6>
                </Col>
                <Col sm="12">
                  <p className="mb-0">{'이름: ' + props.customer_info.name}</p>
                  <p className="mb-0">
                    {'이메일: ' + props.customer_info.email}
                  </p>
                  <p className="mb-0">
                    {'예약날짜: ' + props.customer_info.tour_date}
                  </p>
                  <p className="mb-1">
                    {'참고사항: ' + props.customer_info.details}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <p className="confirm-text-box p-2">
                {'안녕하세요. 투어가이드 ' +
                  props.guide_info.displayName +
                  '입니다. 문의 하신 예약날짜 및 참고사항을 검토후 빠른 시간안에 이메일로 답변 드리겠습니다.'}
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
