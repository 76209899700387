import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { default_alert } from '../../../common/system/alert/alert'
import '../../../sass/user/order.scss'
import ReviewModal from './review_modal'

export default function MyTours(props: {
  isAuthenticated: boolean
  currentPath: string
}) {
  const [upcomingTravels, setUpcomingTravels] = useState<any>([])
  const [upcomingTravelsView, setUpcomingTravlesView] = useState<
    Array<JSX.Element> | JSX.Element
  >()

  const [previousTravels, setPreviousTravels] = useState<any>([])
  const [previousTravelsView, setPreviousTravelsView] = useState<
    Array<JSX.Element> | JSX.Element
  >()

  const [pendingTravels, setPendingTravels] = useState<any>([])
  const [pendingTravelsView, setPendingTravelsView] = useState<
    Array<JSX.Element> | JSX.Element
  >()

  const navigate = useNavigate()

  const [reviewModalSettings, setReviewModalSettings] = useState<{
    open: boolean
    orderId: string
    comment: string
    rating: number
    reviewId: string
  }>({ open: false, orderId: '', comment: '', rating: 0, reviewId: '' })

  type ReviewModel = {
    _id: string
    userId: string
    comment: string
    rating: number
    orderId: string
    inventoryId: string
    reviewId: string
  }

  function handleOpenReviewModal(
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    review: ReviewModel,
    orderId: string
  ): void {
    setReviewModalSettings({
      open: true,
      orderId: orderId,
      comment: review ? review.comment : '',
      rating: review ? review.rating : 0,
      reviewId: review ? review._id : '',
    })
  }

  function fetchData() {
    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )

    if (user_info) {
      const url = process.env.SERVER_HOST + '/api/order/history'
      const token =
        localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''
      axios
        .post(
          url,
          { user_id: user_info?.uid, product_type: ['Tour', 'Ride'] },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const response_data = response.data

          const upcoming_travels: Array<Object> = []
          const previous_travels: Array<Object> = []
          const pending_travels: Array<Object> = []

          Object.keys(response_data).forEach((key) => {
            const user_travel = response_data[key]

            if (user_travel.status === 'Approved') {
              upcoming_travels.push(user_travel)
            } else if (user_travel.status === 'Completed') {
              previous_travels.push(user_travel)
            } else if (user_travel.status === 'Pending') {
              pending_travels.push(user_travel)
            }
          })

          setTimeout(() => {
            setUpcomingTravels(upcoming_travels)
            setPreviousTravels(previous_travels)
            setPendingTravels(pending_travels)
          }, 50)
        })
        .catch((err) => {
          default_alert(err)
        })
    } else {
      navigate('/sign_in')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    console.log('test1')
    let upcoming_travel_list: Array<JSX.Element> | JSX.Element = Object.keys(
      upcomingTravels
    ).map((key, index) => {
      console.log('key:', key)
      const tour_info = upcomingTravels[key].information
      return (
        <Col className="order_container" md="6" key={'upcoming_' + key}>
          <Row>
            <Col md="6" className="">
              <img
                src={require('./../../../assets/images/' +
                  upcomingTravels[key].productPhoto[0])}
                className="figure-img img-fluid rounded order_image_container"
                alt=""
              />
            </Col>
            <Col md="6" className="order_info_container">
              <h6 className="fw-bold mb-1">
                <i className="zmdi zmdi-pin zmdi-hc-lg"></i>&nbsp;
                {tour_info.title}
              </h6>
              <p className="mb-0 pb-0">
                <strong>날짜: </strong>
                {tour_info.tourDate ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>시간: </strong>
                {tour_info.tourTime ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>가이드: </strong>
                {upcomingTravels[key]?.seller.profile.displayName ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>미팅장소: </strong>
                {tour_info.meetingLocation ?? ''}
              </p>
              <a
                type="button"
                href={'/my_tour/' + upcomingTravels[key]._id}
                className="uri-travel-btn btn btn-sm mt-1 w-100 text-center"
              >
                더보기
              </a>
            </Col>
          </Row>
        </Col>
      )
    })

    if (upcomingTravels.length === 0) {
      console.log('no tour')
      upcoming_travel_list = <p>예정된 여행이 없습니다.</p>
    }

    setUpcomingTravlesView(upcoming_travel_list)
  }, [upcomingTravels])

  useEffect(() => {
    let previous_travel_list: Array<JSX.Element> | JSX.Element = Object.keys(
      previousTravels
    ).map((key, index) => {
      const tour_info = previousTravels[key].information

      return (
        <Col className="order_container" md="6" key={'prev_' + key}>
          <Row>
            <Col md="6" className="">
              <img
                src={require('./../../../assets/images/' +
                  previousTravels[key].productPhoto[0])}
                className="figure-img img-fluid rounded order_image_container"
                alt=""
              />
            </Col>
            <Col md="6" className="order_info_container">
              <h6 className="fw-bold mb-1">
                <i className="zmdi zmdi-pin zmdi-hc-lg"></i>&nbsp;
                {tour_info.title}
              </h6>
              <p className="mb-0 pb-0">
                <strong>날짜: </strong>
                {tour_info.tourDate ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>시간: </strong>
                {tour_info.tourTime ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>가이드: </strong>
                {previousTravels[key]?.seller.profile.displayName ?? ''}
              </p>
              <a
                type="button"
                href={'/my_tour/' + previousTravels[key]._id}
                className="uri-travel-btn btn btn-sm mt-1 w-100 text-center"
              >
                더보기
              </a>
              <button
                className="uri-travel-btn btn btn-sm mt-1 w-100 text-center"
                onClick={(ev) =>
                  handleOpenReviewModal(
                    ev,
                    previousTravels[key].userReview,
                    previousTravels[key]._id
                  )
                }
              >
                {previousTravels[key].userReview ? '리뷰 수정' : '리뷰 남기기'}
              </button>
            </Col>
          </Row>
        </Col>
      )
    })

    if (previousTravels.length === 0) {
      console.log('no previous tour')
      previous_travel_list = <p>종료된 여행이 없습니다.</p>
    }

    setPreviousTravelsView(previous_travel_list)
  }, [previousTravels])

  useEffect(() => {
    let pending_travel_list: Array<JSX.Element> | JSX.Element = Object.keys(
      pendingTravels
    ).map((key, index) => {
      const tour_info = pendingTravels[key].information
      return (
        <Col className="order_container" md="6" key={'prev_' + key}>
          <Row>
            <Col md="6" className="">
              <img
                src={require('./../../../assets/images/' +
                  pendingTravels[key].productPhoto[0])}
                className="figure-img img-fluid rounded order_image_container"
                alt=""
              />
            </Col>
            <Col md="6" className="order_info_container">
              <h6 className="fw-bold mb-1">
                <i className="zmdi zmdi-pin zmdi-hc-lg"></i>&nbsp;
                {tour_info.title}
              </h6>
              <p className="mb-0 pb-0">
                <strong>날짜: </strong>
                {tour_info.tourDate ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>시간: </strong>
                {tour_info.tourTime ?? ''}
              </p>
              <p className="mb-0 pb-0">
                <strong>가이드: </strong>
                {pendingTravels[key]?.seller.profile.displayName ?? ''}
              </p>
              {/* <p className="mb-0 pb-0">
                  <strong>미팅장소: </strong>
                  {tour_info.meetingLocation ?? ''}
                </p> */}
              <a
                type="button"
                href={'/my_tour/' + pendingTravels[key]._id}
                className="uri-travel-btn btn btn-sm mt-1 w-100 text-center"
              >
                더보기
              </a>
              <a
                type="button"
                href={'/checkout/' + pendingTravels[key]._id}
                className="uri-travel-btn btn btn-sm mt-1 w-100 text-center"
              >
                결제 하기
              </a>
            </Col>
          </Row>
        </Col>
      )
    })

    if (pendingTravels.length === 0) {
      console.log('no previous tour')
      pending_travel_list = <p>예약중인 여행이 없습니다.</p>
    }

    setPendingTravelsView(pending_travel_list)
  }, [pendingTravels])

  return (
    <React.Fragment>
      <Row className="app-container mt-5">
        <Col md="12 mb-5">
          <h5 className="fw-bold">예정된 여행</h5>
          {upcomingTravelsView}
        </Col>
        <Col md="12 mb-5">
          <h5 className="fw-bold">예약중인 여행</h5>
          {pendingTravelsView}
        </Col>
        <Col md="12 mb-5">
          <h5 className="fw-bold">종료된 여행</h5>
          {previousTravelsView}
        </Col>
      </Row>
      {reviewModalSettings.open && reviewModalSettings.orderId.length > 0 ? (
        <ReviewModal
          isOpen={reviewModalSettings.open}
          orderId={reviewModalSettings.orderId}
          comment={reviewModalSettings.comment}
          rating={reviewModalSettings.rating}
          reviewId={reviewModalSettings.reviewId}
          toggle={(reload = false) => {
            if (reload) {
              fetchData()
            }
            setReviewModalSettings({
              open: false,
              orderId: '',
              comment: '',
              rating: 0,
              reviewId: '',
            })
          }}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
