import { Form, Field } from '@availity/form'
import '@availity/date/styles.scss'
import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

import * as yup from 'yup'

export const profileBasciInfoCard = (
  profile: {
    displayName: string
    profilePicture: string
    bio: string
    rating: { star: number; count: number }
  },
  vm: any
) => {
  const rate_tmp = (profile.rating.star / profile.rating.count).toFixed(2)
  let rate: JSX.Element | string = ''
  if (!isNaN(parseInt(rate_tmp))) {
    rate = (
      <React.Fragment>
        <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
      </React.Fragment>
    )
  }

  const keepLogin = localStorage.getItem('keepLogin') ?? 'No'

  const profileImage =
    profile.profilePicture.length > 0 ? profile.profilePicture : 'avatar.png'

  let is_user_logged_in = false
  let user_info = null
  if (keepLogin === 'Yes') {
    is_user_logged_in = localStorage.getItem('token') ? true : false
    user_info = JSON.parse(localStorage.getItem('user') ?? 'null')
  } else if (keepLogin === 'No') {
    is_user_logged_in = sessionStorage.getItem('token') ? true : false
    user_info = JSON.parse(sessionStorage.getItem('user') ?? 'null')
  }

  return (
    <Row>
      <Col lg="3" sm="3">
        <img
          src={require('./../../../assets/images/' + profileImage)}
          className="img-fluid guide-profile-avatar"
          alt="profile_pic"
        />
      </Col>
      <Col lg="6" md="8" className="py-2">
        <h5 className="fw-bold">{profile.displayName}</h5>
        <h6>{rate}</h6>
        <p className="guide-profile-description">{profile.bio}</p>
        <p>
          {is_user_logged_in ? (
            <button
              className="uri-travel-btn btn"
              onClick={(ev) => vm.handleClickCustomTourRequest(ev)}
            >
              커스텀 투어 문의하기
            </button>
          ) : (
            <a href="/sign_in" type="button" className="uri-travel-btn btn">
              커스텀 투어 문의하기
            </a>
          )}
        </p>
      </Col>
    </Row>
  )
}

export const displayTourPackages = (tour_data: any, vm: any) => {
  let tour_list:
    | string
    | number
    | boolean
    | JSX.Element[]
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | null
    | undefined = []

  if (Object.keys(tour_data).length > 0) {
    tour_list = Object.keys(tour_data).map((key) => {
      const tour = tour_data[key]

      const basic_info = tour.information

      const rate_tmp = (tour.rating.stars / tour.rating.counts).toFixed(2)
      let rate: JSX.Element | string = ''
      if (!isNaN(parseInt(rate_tmp))) {
        rate = (
          <React.Fragment>
            <i className="zmdi zmdi-star zmdi-hc-lg"></i>&nbsp;{rate_tmp}
          </React.Fragment>
        )
      }

      let price = ''
      let max_capacity: string | number = ''
      if (Array.isArray(tour.price)) {
        const tour_price = []
        const capacity = []

        const price_info = tour.price

        for (const i in price_info) {
          tour_price.push(price_info[i].price)
          capacity.push(price_info[i].maxCapacity)
        }

        const high_price = Math.max(...tour_price)
        const row_price = Math.min(...tour_price)

        price =
          '$' +
          row_price.toLocaleString('en') +
          ' - ' +
          high_price.toLocaleString('en')

        max_capacity = Math.max(...capacity)
      } else {
        price = '$' + tour.price.toLocaleString('en')

        max_capacity = basic_info.maxCapacity
      }

      return (
        <Col className="mt-4" key={'tour_' + key}>
          <Card
            className="tour-item dashboard-tour-card"
            onClick={(ev) => vm.handleClickTourCard(ev, tour._id)}
            aria-hidden="true"
          >
            <CardImg
              className="dashboard-tour-image-card"
              alt="Card image cap"
              src={require('./../../../assets/images/' + tour.productPhoto[0])}
              top
              width="100%"
              style={{
                objectFit: 'cover',
              }}
            />
            <CardBody>
              <CardTitle tag="h6" className="dashboard-tour-card-title">
                {basic_info.title}
              </CardTitle>
              <CardSubtitle tag="h6" className="dashboard-tour-card-info">
                {rate}
              </CardSubtitle>
              <p className="mb-0 dashboard-tour-card-info">
                {price} / 최대 {max_capacity} 인
              </p>
              <p className="mb-0 dashboard-tour-card-info">
                {basic_info.duration} 시간
              </p>
            </CardBody>
          </Card>
        </Col>
      )
    })
  }

  return (
    <Row lg="4" md="3" sm="2" xs="1">
      {tour_list}
    </Row>
  )
}

export const CustomTourInquiryForm = (props: {
  is_open: boolean
  handleFormSubmit: (values: {
    name: string
    email: string
    content: string
  }) => void
  handleClose: (ev: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={props.is_open}
        contentClassName="modal_overflow"
        centered={true}
      >
        <Form
          initialValues={{ name: '', email: '', content: '' }}
          onSubmit={props.handleFormSubmit}
          validationSchema={yup.object().shape({
            name: yup.string().required(),
            email: yup.string().required(),
            content: yup.string().required(),
          })}
        >
          <ModalBody className="border-0">
            <Row>
              <Col sm="12" className="text-center mb-3">
                <span className="fw-bold logo-main-title">URI TRAVEL</span>
                <span className="fw-bold logo-sub-title">
                  - 우리는 여행중 -
                </span>
                <i
                  aria-hidden="true"
                  className="zmdi zmdi-close zmdi-hc-lg close-modal"
                  onClick={(ev) => props.handleClose(ev)}
                ></i>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Field name="name" placeholder="이름" required />
              </Col>
              <Col sm="6">
                <Field name="email" placeholder="이메일" required />
              </Col>
              <Col sm="12">
                <Field
                  className="email-content"
                  name="content"
                  id="content"
                  type="textarea"
                  required
                />
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter className="border-0 pt-0">
            <button
              className="uri-travel-btn btn ml-auto mr-auto"
              type="submit"
            >
              &nbsp;커스텀 투어 문의하기&nbsp;
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
