import React, { ReactNode } from 'react'
import { Col, Row } from 'reactstrap'
import { default_alert } from '../../common/system/alert/alert'
import { GuideApplicationForm } from './guide_application_components'
import axios from 'axios'

class GuideApplicationClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(
    values: {
      firstName: string
      lastName: string
      emailAddress: string
      phoneNumber: string
      description: string
    },
    actions: any
  ) {
    const vm = this

    const url = process.env.SERVER_HOST + '/guide/application'

    const payload = { guide_info: values }

    axios
      .post(url, payload)
      .then((response) => {
        const response_data = response.data

        if (response_data.result) {
          default_alert(
            '신청이 접수되었습니다. 빠른 시일내에 검토하여 답변 드리겠습니다.'
          )

          actions.resetForm()
        } else {
          default_alert(
            '잠시후 다시 신청 부탁드립니다. 이용중 불편을 드려서 죄송합니다.'
          )
        }
      })
      .catch((err) => {
        default_alert(
          '잠시후 다시 신청 부탁드립니다. 이용중 불편을 드려서 죄송합니다.'
        )
      })
  }

  render(): ReactNode {
    return (
      <Row className="app-container mt-3">
        <Col md="12">
          <GuideApplicationForm handleFormSubmit={this.handleFormSubmit} />
        </Col>
      </Row>
    )
  }
}

const GuideApplication = GuideApplicationClass

export default GuideApplication
