export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <h4 className="fw-bold ws-3">URI TRAVEL</h4>
        <h6 className="ws-3">- 우리는 여행중 -</h6>
        <br />
        <p className="mb-1">
          <a className="text-light" href="/become_guide">
            가이드 신청하기
          </a>
        </p>
        <p className="mb-1">
          <a className="text-light" href="/about_us">
            회사 소개
          </a>
        </p>
        <p className="mb-1">
          <a className="text-light" href="/user_terms_of_service">
            이용 약관
          </a>
        </p>
        <p className="mb-1">
          <a className="text-light" href="/">
            개인정보 처리방침
          </a>
        </p>
        <p>Copyright &copy; {new Date().getFullYear()} URI TRAVEL INC.</p>
      </div>
    </footer>
  )
}
