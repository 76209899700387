import React from 'react'
import { Col, Row } from 'reactstrap'

import './../../sass/guide_application/guide_application.scss'

export default function GuideTermsOfService() {
  return (
    <React.Fragment>
      <Row className="app-container">
        <Col md="12">
          <div className="right center mb-4">
            <h3 className="main-theme-text-color">
              우리트레블 가이드 이용약관
            </h3>
            시행일자: 2013년 4월 1일
          </div>
          <div className="right center mb-3">
            <h4 className="text-decoration-underline">
              가이드 운영 세부규정(지침)
            </h4>
            <ol>
              <li>
                {' '}
                자격요건: 모든 가이드는 우리트래블이 요구하는 자격요건을
                충족해야 하며, 아래와 같은 4가지 필수 자격 요건이 있습니다:
                <ol>
                  <li>
                    {' '}
                    전문적인 지식과 경험: 우리트래블 가이드는 여행 분야에서
                    전문적인 지식과 경험을 가져야 합니다. 관광지 및 역사, 문화,
                    예술, 자연 등과 같은 다양한 분야에 대한 지식을 갖추고 있어야
                    합니다.
                  </li>
                  <li>
                    {' '}
                    숙련된 언어 능력: 우리트래블 가이드는 고객들과 의사소통이
                    원활하게 이루어질 수 있도록 숙련된 언어 능력을 갖추어야
                    합니다. 특히, 영어와 한국어를 구사할 수 있는 능력은
                    필수적입니다.
                  </li>
                  <li>
                    {' '}
                    지도 및 안전 관리 능력: 우리트래블 가이드는 여행 중 고객들의
                    안전을 최우선으로 생각해야 합니다. 따라서, 긴급 상황 발생 시
                    신속하고 적극적인 대처 능력과 지도 및 안전 관리 능력이
                    요구됩니다.
                  </li>
                  <li>
                    {' '}
                    친절하고 배려심이 있는 태도: 우리트래블 가이드는 고객들과
                    함께 하는 시간 동안 친절하고 배려심이 있는 태도로 서비스를
                    제공해야 합니다. 고객의 다양한 요구와 상황에 대처하며,
                    적극적인 문제 해결 능력을 갖추어야 합니다.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                서비스 제공: 가이드는 우리트래블에서 제공하는 서비스를
                신의성실의 원칙에 따라 성실히 제공해야 합니다.
              </li>
              <li>
                {' '}
                예약 취소: 가이드는 사전에 예약한 서비스를 제공하지 못할 경우
                적절한 조치를 취해야 합니다. 또한, 예약을 취소하고자 하는
                경우에는 미리 우리트래블에 알려야 하며, 회사의 취소규정에 따라
                처리됩니다.
              </li>
              <li>
                {' '}
                안전 및 보험: 가이드는 여행 중 안전을 최우선으로 생각하며, 여행
                참가자의 안전을 보장하기 위한 조치를 취해야 합니다. 또한,
                가이드는 여행 중 발생할 수 있는 사고에 대비하여 적절한 보험에
                가입해야 합니다.
              </li>
              <li>
                {' '}
                비밀 유지: 가이드는 우리트래블 및 여행 참가자의 개인정보를
                적절히 관리하고, 비밀을 유지해야 합니다.
              </li>
              <li>
                {' '}
                지적재산권: 가이드는 우리트래블이 제공하는 서비스와 관련된
                지적재산권을 존중해야 합니다.
              </li>
              <li>
                {' '}
                계약 해지: 우리트래블은 가이드가 이용약관을 위반한 경우, 계약을
                해지할 수 있습니다.
              </li>
              <li>
                {' '}
                손해배상: 가이드는 우리트래블 및 여행 참가자에게 발생한 손해에
                대해 배상할 책임이 있습니다.
              </li>
              <li>
                {' '}
                기타 규정: 본 이용약관에서 규정되지 않은 사항에 대해서는
                우리트래블이 정하는 바에 따릅니다.
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h4 className="text-decoration-underline">
              우리트래블 가이드 승인과정
            </h4>
            <ol>
              <li>
                신청서 작성 및 제출: 우리트래블 가이드를 원하는 자는 우리트래블
                웹사이트를 통해 온라인 신청서를 작성하고 제출합니다.
              </li>

              <li>
                서류 검토: 신청서를 제출한 후, 우리트래블 운영팀은 서류를
                검토하며, 자격 요건을 충족하는 경우 신청자에게 면접 일정을
                안내합니다.
                <br />
              </li>
              <li>
                {' '}
                면접: 우리트래블 운영팀은 신청자와 면접을 진행하며, 해당
                신청자의 여행 및 가이드 경험, 언어 능력, 지도 및 안전 관리 능력,
                태도 등에 대해 평가합니다.
                <br />
              </li>
              <li>
                {' '}
                가이드 교육: 가이드로 선발된 신청자는 우리트래블에서 제공하는
                가이드 교육 프로그램에 참여합니다. 이 교육은 고객 서비스 및
                안전, 지도 및 관리, 문화 및 역사에 대한 지식 등을 포함합니다.
                <br />
              </li>
              <li>
                {' '}
                가이드 등록: 가이드 교육을 마친 후, 우리트래블 운영팀은 가이드
                등록을 완료합니다. 등록된 가이드는 고객들에게 제공되며, 가이드
                활동을 위해 일정 및 가이드 수당 등이 제공됩니다.
                <br />
              </li>
              <li>
                {' '}
                가이드 평가: 우리트래블에서는 가이드 활동 중에도 평가가
                이루어지며, 평가 결과에 따라 가이드 수당이 변경될 수 있습니다.
                <br />
              </li>
              <li>
                {' '}
                가이드 유지 및 개선: 우리트래블은 고객 서비스 향상을 위해
                가이드들의 유지 및 개선을 지속적으로 진행하고 있습니다.
                가이드들은 우리트래블에서 제공하는 가이드 교육과 피드백을
                받으며, 지속적인 자기 개선을 통해 고객에게 보다 나은 서비스를
                제공합니다.
                <br />
              </li>
            </ol>
          </div>
          <div className="right center mb-3">
            <h4 className="text-decoration-underline">
              우리트래블 가이드 자격박탈 규정
            </h4>
            <ol>
              <li>
                신청서 작성 및 제출: 우리트래블 가이드를 원하는 자는 우리트래블
                웹사이트를 통해 온라인 신청서를 작성하고 제출합니다.
              </li>

              <li>
                안전 위반: 가이드는 여행 도중에 고객들의 안전을 최우선으로
                생각해야 합니다. 만약 가이드가 안전 관련 규정을 위반하거나
                안전에 위험을 끼치는 행동을 한 경우, 가이드 자격을 박탈당할 수
                있습니다.
                <br />
              </li>
              <li>
                비도덕적인 행동: 가이드는 고객들의 신뢰를 유지해야 합니다. 만약
                가이드가 비도덕적인 행동을 하거나 법률을 위반한 경우, 가이드
                자격을 박탈당할 수 있습니다.
                <br />
              </li>
              <li>
                고객 서비스 부적절: 가이드는 고객 서비스에 대한 전문성을
                유지해야 합니다. 만약 가이드가 고객을 대하는 방식이 부적절하거나
                고객 서비스에 실패한 경우, 가이드 자격을 박탈당할 수 있습니다.
                <br />
              </li>
              <li>
                자격 요건 미충족: 가이드는 자격 요건을 충족해야 합니다. 만약
                가이드가 자격 요건을 충족하지 못하는 경우, 가이드 자격을
                박탈당할 수 있습니다.
                <br />
              </li>
            </ol>
            이러한 경우에는 우리트래블 운영팀에서 심의를 거쳐 가이드 자격을
            박탈할 수 있습니다.
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}
