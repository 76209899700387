import axios from 'axios'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { default_alert } from '../../../common/system/alert/alert'
import { Input, Form, Field, CheckboxGroup, Checkbox } from '@availity/form'
import { Alert, Col } from 'reactstrap'
import PasswordStrengthChecker from '../../../common/system/password_strength_checker/password_strength_checker'
import * as yup from 'yup'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './../../../sass/auth/sign_up.scss'

export default function UserProfile(props: {
  isAuthenticated: boolean
  currentPath: string
}) {
  const [userData, setUserData] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('')

  const [inputValues, setInputValues] = useState({
    password: '',
    confirmedPassword: '',
    phoneNumber: '',
  })

  const navigate = useNavigate()

  const formRef = useRef<any>(null)

  useEffect(() => {
    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )

    if (user_info) {
      const url = process.env.SERVER_HOST + '/api/user/profile'
      const token =
        localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''
      axios
        .post(
          url,
          { user_id: user_info?.uid, product_type: 'Tour' },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const response_data = response.data

          setUserData(response.data)

          setInputValues({
            password: '',
            confirmedPassword: '',
            phoneNumber: response_data?.phoneNumber ?? '',
          })

          formRef.current.setFieldValue(
            'phoneNumber',
            response_data?.phoneNumber ?? ''
          )
        })
        .catch((err) => {
          default_alert(err)
        })
    } else {
      navigate('/sign_in')
    }
  }, [])

  function handelInputChange(newValue: string, name: string): void {
    const new_input_values = JSON.parse(JSON.stringify(inputValues))

    new_input_values[name] = newValue

    setInputValues(new_input_values)
  }

  const handleSubmit = async (values: any) => {
    const payload: any = {
      emailAddress: userData.emailAddress,
      phoneNumber: values.phoneNumber,
    }

    if (values.password && values.password.length > 0) {
      payload.password = values.password
    }

    axios
      .post(process.env.SERVER_HOST + '/api/user/update_profile', payload)
      .then((response) => {
        setErrorMessage(
          <Col md="12 mt-3">
            <Alert color="success">{response.data.message}</Alert>
          </Col>
        )
      })
      .catch((error) => {
        setErrorMessage(
          <Col md="12 mt-3">
            <Alert color="danger">{error}</Alert>
          </Col>
        )
      })
      .finally(function () {
        setInputValues({
          ...inputValues,
          password: '',
          confirmedPassword: '',
        })

        setTimeout(() => {
          setErrorMessage('')
        }, 2000)
      })
  }
  return (
    <div className="app-container mt-5">
      <div className="auth-container">
        <Col md="12" className="text-center">
          <div className="mb-3">
            <img
              src={require('./../../../assets/images/avatar.png')}
              className="figure-img img-fluid rounded"
              alt="profile"
              style={{ maxWidth: '250px' }}
            />
          </div>
          <div className="mb-3">
            <h5 className="fw-bold">
              {userData.firstName + ' ' + userData.lastName}
            </h5>
          </div>
        </Col>
        <Col md="12">
          <Form
            innerRef={formRef}
            initialValues={inputValues}
            validationSchema={yup.object().shape({
              phoneNumber: yup
                .string()
                .isRequired(true, '전화번호를 입력하세요.'),
            })}
            onSubmit={handleSubmit}
          >
            <div className="auth-form-content">
              <h4 className="auth-form-title mt-3">회원정보</h4>
              {errorMessage}
              <div className="form-group mt-3">
                <label htmlFor="emailAddress">이메일</label>
                <Input
                  name="emailAddress"
                  type="email"
                  className="form-control mt-1"
                  value={userData?.emailAddress ?? ''}
                  disabled
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="firstName">이름</label>
                <Input
                  name="firstName"
                  type="text"
                  className="form-control mt-1"
                  value={userData?.firstName ?? ''}
                  disabled
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="lastName">성</label>
                <Input
                  name="lastName"
                  type="text"
                  className="form-control mt-1"
                  value={userData?.lastName ?? ''}
                  disabled
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="phoneNumber">전화번호</label>
                <PhoneInput
                  placeholder="Enter Phone Number"
                  value={inputValues['phoneNumber']}
                  onChange={(newValue) => {
                    formRef.current.setFieldTouched('phoneNumber')
                    formRef.current.setFieldValue('phoneNumber', newValue ?? '')

                    return handelInputChange(newValue ?? '', 'phoneNumber')
                  }}
                />
                <Field
                  name="phoneNumber"
                  type="hidden"
                  className="form-control mt-1"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password">비밀번호</label>
                <Field
                  name="password"
                  autoComplete="off"
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'password')
                  }
                  value={inputValues.password}
                  validate={(value) => {
                    const special_chars =
                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

                    if (
                      value.legnth > 0 &&
                      (!special_chars.test(value) ||
                        !value.match(/[A-Z]/) ||
                        value.length < 6 ||
                        !value.match(/[a-z]/) ||
                        !value.match(/\d/))
                    ) {
                      return '비밀번호가 단순합니다. 6자 이상의 영문 대/소문자, 숫자 및 특수문자 조합으로 입력하셔야 합니다.'
                    }
                  }}
                />
                <PasswordStrengthChecker password={inputValues.password} />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="confirm">비밀번호 확인</label>
                <Field
                  name="confirmedPassword"
                  autoComplete="off"
                  type="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  onChange={(ev) =>
                    handelInputChange(ev.target.value, 'confirmedPassword')
                  }
                  validate={(value) => {
                    if (value !== inputValues.password) {
                      return '비밀번호가 일치하지 않습니다.'
                    }
                  }}
                  value={inputValues.confirmedPassword}
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="uri-travel-btn btn">
                  변경 하기
                </button>
              </div>
              <div className="d-grid gap-2 mt-3">
                <a type="button" href="/" className="btn-secondary btn">
                  메인 페이지
                </a>
              </div>
            </div>
          </Form>
        </Col>
      </div>
    </div>
  )
}
