import React from 'react'
import { Col, Row } from 'reactstrap'
import {
  displayTourPackages,
  processFormatHeaderImages,
  setCityListForCategorizedTourPackages,
} from './dashboard_components'

import axios from 'axios'
class DashboardClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      active_location: 'Vancouver',
      tours_data: [],
      locations: [],
      guide_data: [],
    }

    this.fetchData = this.fetchData.bind(this)
    this.handleClickTourCard = this.handleClickTourCard.bind(this)
    this.handleClickGuideThumnail = this.handleClickGuideThumnail.bind(this)
  }

  componentDidMount() {
    const vm = this
    vm.fetchData()
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // TODO:: sepcify type
    const vm = this

    if (
      prevState &&
      prevState.active_location &&
      vm.state &&
      vm.state.active_location &&
      prevState.active_location !== vm.state.active_location
    ) {
      vm.fetchData()
    }
  }

  fetchData() {
    const vm = this

    const url = process.env.SERVER_HOST + '/dashboard/veiw_settings'

    const payload = {
      location: vm.state.active_location,
    }

    axios.post(url, payload).then((response) => {
      const response_data = response.data

      const vm = this

      let tours_data = []

      let locations = []

      let guide_data = []

      if (Object.keys(response_data.tours_data).length > 0) {
        tours_data = response_data.tours_data
      }

      if (Object.keys(response_data.guide_data).length > 0) {
        guide_data = response_data.guide_data
      }

      if (response_data.locations.length > 0) {
        locations = response_data.locations
      }

      vm.setState({ tours_data, locations, guide_data })
    })
  }

  handleClickTourCard(
    ev: React.MouseEvent<HTMLButtonElement>,
    inventory_id: string
  ) {
    const url = 'tour_package/' + inventory_id

    window.open(url)
  }

  handleClickGuideThumnail(
    ev: React.MouseEvent<HTMLButtonElement>,
    guide_id: string
  ) {
    const url = 'guide_profile/' + guide_id

    window.open(url)
  }

  handleChangeActiveLocation(location: string) {
    const vm = this
    vm.setState({ active_location: location })
  }

  render(): React.ReactNode {
    const vm = this

    const { tours_data, active_location, locations, guide_data } = vm.state

    // TODO :: implenent dynmaic image slide based on images getting from server.
    const header_images = processFormatHeaderImages()

    const tour_packages = displayTourPackages(vm, tours_data, guide_data)

    const cities = setCityListForCategorizedTourPackages(
      vm,
      active_location,
      locations
    )

    return (
      <React.Fragment>
        <Row className="app-container">
          <Col xs="12">{header_images}</Col>
          <Col xs="12" className="mt-5">
            <h4 className="fw-bold">로컬 가이드와 함께 하는 여행</h4>
            {cities}
          </Col>
          <Col xs="12" className="mt-3">
            {tour_packages}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const Dashboard = DashboardClass
export default Dashboard
