import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import './../../sass/header.scss'

export const HeaderBar = (props: {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { setIsAuthenticated, isAuthenticated } = props

  const [accountDropdown, setAccountDropdown] = useState<string | JSX.Element>(
    ''
  )

  let status_bar: string | JSX.Element = ''

  if (process.env.NODE_ENV === 'development') {
    status_bar = (
      <div className="page-title">
        <div className="row uri-travel-notificationbar uri-travel-notificationbar-local">
          You are currently in the Local environment of URI TRAVEL APP
        </div>
      </div>
    )
  }

  const navigate = useNavigate()

  const sign_out = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    setIsAuthenticated(false)
    navigate('/')
  }

  useEffect(() => {
    setAccountDropdown(userAccountDropdown(isAuthenticated, sign_out))
  }, [isAuthenticated])

  return (
    <React.Fragment>
      {status_bar}
      <nav className="navbar_sticky navbar navbar-expand-lg navbar-light border-bottom py-0">
        <div className="container-fluid nav-container">
          <a className="navbar-brand" href="/">
            <img
              src={require('./../../assets/images/logo_v2_2_fit.png')}
              className="figure-img img-fluid rounded logo-size"
              alt=""
            />
            {/* <span className="fw-bold logo-main-title">URI TRAVEL</span>
            <span className="fw-bold logo-sub-title">- 우리는 여행중 -</span> */}
          </a>

          <ul className="nav justify-content-end">
            <li className="nav-item">
              <a
                type="button"
                className="btn btn-outline-light header-btn mt-1"
                href="/become_guide"
              >
                가이드 신청하기
              </a>
            </li>
            <li className="nav-item">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn btn-outline-light dropdown-toggle header-btn"
                  tag="button"
                  style={!isAuthenticated ? { marginTop: '4px' } : {}}
                >
                  {isAuthenticated ? (
                    <React.Fragment>
                      <i
                        className="zmdi zmdi-apps zmdi-hc-lg mr-1"
                        style={{ verticalAlign: '20%' }}
                      ></i>
                      <i className="zmdi zmdi-account-circle zmdi-hc-2x"></i>{' '}
                    </React.Fragment>
                  ) : (
                    '로그인'
                  )}
                </DropdownToggle>
                <DropdownMenu>{accountDropdown}</DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  )
}

function userAccountDropdown(
  isAuthenticated: boolean,
  sign_out: () => void
): string | JSX.Element {
  let dropdown: string | JSX.Element = ''
  if (isAuthenticated) {
    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )
    dropdown = (
      <React.Fragment>
        <DropdownItem
          tag="h6"
          className="fw-bold"
          disabled
          style={{ color: '#333' }}
        >
          {(user_info?.lastName ?? '') + (user_info?.firstName ?? '') + '님'}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem href="/my_tour" tag="a">
          투어
        </DropdownItem>
        {/*  <DropdownItem href="/messages" tag="a">
          메세지
    </DropdownItem> */}
        <DropdownItem href="/my_profile" tag="a">
          계정
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={sign_out} tag="button">
          로그아웃
        </DropdownItem>
      </React.Fragment>
    )
  } else {
    dropdown = (
      <React.Fragment>
        <DropdownItem href="/sign_in" tag="a">
          로그인
        </DropdownItem>
        <DropdownItem href="/sign_up" tag="a">
          회원 가입
        </DropdownItem>
      </React.Fragment>
    )
  }

  return dropdown
}
