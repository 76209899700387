import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ExchangeRate, Order } from './types'
import PageNotFound from './../../../common/template/errors/404'
import { Col, Row } from 'reactstrap'
import { OrderDetail, PaymentDetail, ProductDetail } from './common_function'

export default function PaymentCompletion(props: {
  isAuthenticated: boolean
  currentPath: string
}) {
  const { currentPath, isAuthenticated } = props

  const navigate = useNavigate()

  const { order_id } = useParams()

  const token =
    localStorage.getItem('token') ?? sessionStorage.getItem('token') ?? ''

  const [orderInfo, setOrderInfo] = useState<Order | null>(null)
  const [exchangeRate, setExchangeRate] = useState<ExchangeRate | null>(null)

  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    const user_info = JSON.parse(
      localStorage.getItem('user') ?? sessionStorage.getItem('user') ?? 'null'
    )

    const payload = {
      order_id,
    }

    async function fetchData() {
      fetch(process.env.SERVER_HOST + '/api/order/info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          if (Object.keys(data.order_info).length === 0) {
            setShowError(true)
          } else {
            setOrderInfo(data.order_info)
            setExchangeRate(data.exchange_rate.value)
          }
        })
        .catch((err) => {
          setShowError(true)
        })
    }

    if (user_info) {
      fetchData()
    } else {
      navigate('/sign_in')
    }
  }, [])

  return (
    <React.Fragment>
      {showError ? (
        <PageNotFound />
      ) : (
        <div className="app-container mt-3">
          <Row>
            <Col md="12" className="mb-3">
              <Row>
                <Col md={6}>
                  결제 날짜:{' '}
                  {new Date(
                    orderInfo?.paymentInformation?.createdAt ?? ''
                  ).toString()}
                </Col>
                <Col md={6} className="text-right">
                  <a href="/" className="btn uri-travel-btn">
                    메인 페이지
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={5} className="product p-4 payment-page-panel">
              <h5 className="fw-bold mb-2 text-center">투어 정보</h5>
              <div className="border-top pt-3 mb-2">
                {
                  <ProductDetail
                    information={orderInfo?.information ?? null}
                    guide={orderInfo?.seller?.profile?.displayName ?? ''}
                  />
                }
              </div>
              <div className="border-top pt-3">
                <p className="mb-0 pb-0 fw-bold">환불 정책:</p>
                <p className="mb-0 pb-0">
                  여행 출발일(D-Day) 30일 전 취소 시: 예약금 전액 환불
                </p>
                <p className="mb-0 pb-0">
                  여행 출발일(D-Day) 15일 ~ 29일 전 취소 시: 총 여행 비용의 70%
                  환불
                </p>
                <p className="mb-0 pb-0">
                  여행 출발일(D-Day) 14일 ~ 7일 전 취소 시: 총 여행 비용의 50%
                  환불
                </p>
                <p className="mb-0 pb-0">
                  여행 출발일(D-Day) 7일 이내 취소 시: 총 여행 비용의 20% 환불
                </p>
                <p>투어 도시의 현지 시간 기준입니다.</p>
              </div>
            </Col>
            <Col md={2}></Col>
            <Col md={5} className="order">
              <Row>
                <Col
                  md={12}
                  className="payment-detail p-4 payment-page-panel mb-4"
                >
                  {orderInfo ? (
                    <OrderDetail
                      price={orderInfo.price}
                      currency={orderInfo.currency}
                      exchangeRate={exchangeRate}
                    />
                  ) : (
                    ''
                  )}
                </Col>
                <Col md={12} className="payment-method p-4 payment-page-panel">
                  <div className="App">
                    {orderInfo ? (
                      <PaymentDetail
                        paymentInformation={orderInfo.paymentInformation}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}
